import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GuildService } from '../services/guild.service';

@Injectable({
  providedIn: 'root'
})
export class adminPermissionGuard {
  constructor(
    private guildService: GuildService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const guildId = route.parent?.paramMap.get('id') || route.paramMap.get('id');

    if (!guildId) {
      this.router.navigate(['/']); // Redirect if guild ID is not found
      return false;
    }

    return this.guildService.getGuildData(guildId).pipe(
      map(guild => {
        const hasPermission = this.checkPermissions(guild); // Implement this method based on your requirements
        if (!hasPermission) {
          this.router.navigate(['/no-access']); // Redirect if the user doesn't have permission
          return false;
        }
        return true;
      }),
      catchError((error) => {
        this.router.navigate(['/error']); // Handle potential errors such as network issues
        return of(false);
      })
    );
  }

  private checkPermissions(guild: any): boolean {
    // Define your logic to determine if the user has the correct permissions
    return guild && guild.owner;
  }
}
