import { Component, OnInit } from '@angular/core';
import { faDiscord, faPatreon, faPaypal } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html'
})
export class SupportComponent implements OnInit {
  faIcons = {
    patreon: faPatreon,
    discord: faDiscord,
    paypal: faPaypal,
  }

  constructor() { }

  ngOnInit(): void {
  }
}