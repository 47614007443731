import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from './pages/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { GettingStartedComponent } from './pages/getting-started/getting-started.component';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SupportComponent } from './pages/support/support.component';
import { PremiumComponent } from './pages/premium/premium.component';
import { MigrationComponent } from './pages/migration/migration.component';
import { GuildSelectionComponent } from './pages/guild-selection/guild-selection.component';
import { GuildDashboardComponent } from './pages/guild-dashboard/guild-dashboard.component';
import { ChannelCardComponent } from './pages/guild-dashboard/channel-card/channel-card.component';
import { ServerCardComponent } from './pages/guild-dashboard/server-card/server-card.component';
import { GearCardComponent } from './pages/guild-dashboard/gear-card/gear-card.component';
import { FastspringCardComponent } from './pages/guild-dashboard/fastspring-card/fastspring-card.component';
import { UserCardComponent } from './pages/guild-dashboard/user-card/user-card.component';
import { OverviewCardComponent } from './pages/guild-dashboard/overview-card/overview-card.component';
import { SignupTemplateCardComponent } from './pages/guild-dashboard/signupTemplate-card/signupTemplate-card.component';
import { adminPermissionGuard } from './guards/admin-permission.guard';
import { signupTemplateLimitsGuard } from './guards/signup-template-limits.guard';
import { EventCardComponent } from './pages/guild-dashboard/event-card/event-card.component';
import { organizerPermissionGuard } from './guards/organizer-permission.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'getting-started', component: GettingStartedComponent },
  { path: 'help', component: HelpComponent },
  { path: 'about', component: AboutComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'support', component: SupportComponent },
  { path: 'premium', component: PremiumComponent },
  { path: 'migration', component: MigrationComponent },
  { path: 'guilds', canActivate: [IsLoggedInGuard], component: GuildSelectionComponent },
  {
    path: 'guilds/:id',
    canActivate: [IsLoggedInGuard],
    component: GuildDashboardComponent,
    children: [
      { path: '', component: OverviewCardComponent,},
      { path: 'overview', component: OverviewCardComponent },
      { path: 'server', component: ServerCardComponent },
      { path: 'channel/:channelId', component: ChannelCardComponent },
      { path: 'event/create', component: EventCardComponent, canActivate: [organizerPermissionGuard]},
      { path: 'signup-template/new', component: SignupTemplateCardComponent, canActivate: [adminPermissionGuard, signupTemplateLimitsGuard]},
      { path: 'signup-template/:signupTemplateId', component: SignupTemplateCardComponent, canActivate: [adminPermissionGuard]},
      { path: 'gears', component: GearCardComponent },
      { path: 'premium', component: FastspringCardComponent },
      { path: 'user', component: UserCardComponent },
    ]
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
