import { Component, Input } from '@angular/core';
import { EmbedService } from '../../services/embed.service';
import { DiscordEmbed } from './embed.model';

@Component({
  selector: 'app-embed-builder',
  templateUrl: './embed-builder.component.html',
  styleUrls: ['./embed-builder.component.css']
})
export class EmbedBuilderComponent {
  @Input() config: DiscordEmbed; // You can design this to be more specific
  embed: DiscordEmbed;

  protected embedService: EmbedService;

  constructor(embedService: EmbedService) {
    this.embedService = embedService;
    this.embedService.currentEmbed.subscribe(embed => this.embed = embed);
  }

  ngOnInit(): void {
    this.embedService.currentEmbed.subscribe(embed => {
      // Initialize or adjust embed based on input config
      this.embed = this.config || embed;
    });
  }

  updateEmbed(part: Partial<DiscordEmbed>) {
    this.embedService.updateEmbed(part);
  }

  addField() {
    this.embedService.addField({ name: '', value: '', inline: false });
  }

  removeField(index: number) {
    this.embedService.removeField(index);
  }

  toggleInline(index: number) {
    const newFields = [...this.embed.fields];
    newFields[index].inline = !newFields[index].inline;
    this.embedService.updateEmbed({ fields: newFields });
  }
}
