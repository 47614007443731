import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appDisableLink]'
})
export class DisableLinkDirective implements OnInit, OnDestroy {
  @Input() appDisableLink: boolean;

  private listener: EventListener;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.listener = (event: Event) => {
      if (this.appDisableLink) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    };

    // Attach the listener in the capture phase
    this.el.nativeElement.addEventListener('click', this.listener, true);
  }

  ngOnDestroy(): void {
    // Clean up the listener when the directive is destroyed
    this.el.nativeElement.removeEventListener('click', this.listener, true);
  }
}
