<header class="d-flex flex-column flex-md-row align-items-md-center p-5 bg-light">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Migration Guide</h1>
    <p class="bd-lead">Convert from prefix (<code>!</code>) commands to slash (<code>/</code>) commands</p>
  </div>
</header>

<section class="p-5">
  <div class="row justify-content-center">
    <div class="col-xl-4 col-lg-4 mb-3 align-items-stretch">
        <div class="d-lg-none">
          <h2>Table of content</h2>
          <ul>
            <li><a href="migration#why_is_this_needed">Why is this needed?</a></li>
            <li><a href="migration#what_are_slash_commands">What are slash commands?</a></li>
            <li><a href="migration#command_migration">Command Migration</a></li>
          </ul>
        </div>
        <div class="d-none d-lg-block" style="position:fixed;">
          <h2>Table of content</h2>
          <ul>
            <li><a href="migration#why_is_this_needed">Why is this needed?</a></li>
            <li><a href="migration#what_are_slash_commands">What are slash commands?</a></li>
            <li><a href="migration#command_migration">Command Migration</a></li>
          </ul>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 mb-3 align-items-stretch">
      <h2 id="why_is_this_needed">Why is this needed?</h2>
      <p>
      Some time ago <a href="https://support-dev.discord.com/hc/articles/4404772028055" target="_blank">Discord announced</a> that they will limit the access to the message content for bots.
      This means that every bot that is used in 100+ guilds will not be able to read the message content of other users anymore.
      This new "feature" will drop in April 2022.<br>
      Since <b>all</b> prefix triggered commands relate on the message content, I have rewritten all commands to work with the new slash option.
      </p>
      
      <h2 id="what_are_slash_commands">What are slash commands?</h2>
      The new "slash" (or also called "application commands") are a new way to interact with bots on Discord. In many ways it makes it more easy for us developers to handle your user input and
      it will (most likely) make it more user friendly for you as well (once you are used to it). However, the only official Discord article which I was able to find is this one:
      <a href="https://discord.com/blog/slash-commands-are-here">Slash Commands Are Here</a>.<br><br>
      So... let me shortly describe what this article says:<br>
      <ul>
        <li><b>Till now</b> you needed to use a prefix (like <code>!</code>) to interact with the bot <br><img class="col-lg-6 text-white showcase-img" src="https://assets-global.website-files.com/5f9072399b2640f14d6a2bf4/611af477165f0b6faa54750e_0*EWl0KZcSqZCC2W9S.png"></li><br>
        <li><b>Now</b> you enter <code>/</code> and all registered applications/bots in your server get listed above the input field <br><img class="col-lg-6 text-white showcase-img" src="https://assets-global.website-files.com/5f9072399b2640f14d6a2bf4/611af47714cd1538762ca2d3_0*Q5CzShKq5zm3kzcv.png"></li><br>
        <li>You can now either select an icon (bot) from the left to get moved to the commands from the bot <b>or</b> continue to filter down the commands by entering e.g. <code>afk</code>.</li><br>
        <li>If you do not see <b>Raid Organizer</b> in your application list, use <a href="https://discord.com/api/oauth2/authorize?client_id=658698521570181140&permissions=92224&scope=applications.commands%20bot" target="_blank">this link</a> to update the permissions of the bot.</li>
      </ul>

      <h2 id="command_migration">Command Migration</h2>
      Since there is a limit of how many slash commands a bot can have, I needed to make command groups.<br>
      You might already know them from other bots. Basically, if you have the commands <code>event_create</code>, <code>event_edit</code> and <code>event_delete</code> you can create a group called <code>event</code>
      and make <code>create</code>, <code>edit</code>, <code>remove</code> members of that group.<br>
      Difference:<br>
      <ul>
        <li><code>event_create</code>, <code>event_edit</code> and <code>event_delete</code> count as three commands</li>
        <li>The group <code>event</code> also has 3 sub commands, but actually counts as 1 command to Discord.</li>
        <li>The group commands are executed via <code>event create</code>, <code>event edit</code> and <code>event delete</code> (note the missing <code>_</code>!)</li>
      </ul>
      Below is a migration table from the old command (<code>create_event</code>) to the new command (<code>event create</code>)<br><br>
      <div class="col-xl-10 col-lg-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Old Command</th>
              <th>New Command</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Channel Commands</b></td>
            </tr>
            <tr>
              <td>setup_channel</td>
              <td>channel setup</td>
            </tr>
            <tr>
              <td>remove_channel</td>
              <td>channel remove</td>
            </tr>
            <tr>
              <td>change_channel</td>
              <td>channel change</td>
            </tr>
            <tr>
              <td>toggle_user_confirmation_message</td>
              <td>toggle user_confirmation_message</td>
            </tr>
            <tr>
              <td>toggle_organizer_unconfirmed_messages</td>
              <td>toggle organizer_unconfirmed_messages</td>
            </tr>
            <tr>
              <td>toggle_footer_time</td>
              <td>toggle footer_time</td>
            </tr>
            <tr>
              <td>toggle_export_event_on_close</td>
              <td>toggle export_event_on_close</td>
            </tr>
            <tr>
              <td>toggle_event_closure</td>
              <td>toggle event_closure</td>
            </tr>
            <tr>
              <td>toggle_sign_up_count</td>
              <td>toggle sign_up_count</td>
            </tr>
            <tr>
              <td>toggle_here_notification</td>
              <td>toggle here_notification</td>
            </tr>
            <tr>
              <td>toggle_account_names</td>
              <td>toggle account_names</td>
            </tr>
            <tr>
              <td>get_clean_mode</td>
              <td>clean_mode get</td>
            </tr>
            <tr>
              <td>set_clean_mode</td>
              <td>clean_mode set</td>
            </tr>
            <tr>
              <td>get_default_template</td>
              <td>default_template get</td>
            </tr>
            <tr>
              <td>set_default_template</td>
              <td>default_template set</td>
            </tr>
            <tr>
              <td>get_event_deletion_time</td>
              <td>event_deletion_time get</td>
            </tr>
            <tr>
              <td>set_event_deletion_time</td>
              <td>event_deletion_time set</td>
            </tr>
            <tr>
              <td>add_event_start_notification</td>
              <td>event_start_notification add</td>
            </tr>
            <tr>
              <td>remove_event_start_notification</td>
              <td>event_start_notification remove</td>
            </tr>
            <tr>
              <td>edit_event_start_notification</td>
              <td>event_start_notification edit state</td>
            </tr>
            <tr>
              <td>edit_event_start_notification</td>
              <td>event_start_notification edit time</td>
            </tr>
            <tr>
              <td>set_sign_up_number_behavior</td>
              <td>sign_up_number_behavior set</td>
            </tr>
            <tr>
              <td>set_confirm_behavior</td>
              <td>confirm_behavior set</td>
            </tr>
            <tr>
              <td>set_export_channel</td>
              <td>export_channel set</td>
            </tr>
            <tr>
              <td>set_event_close_time</td>
              <td>event_close_time set</td>
            </tr>
            <tr>
              <td>set_event_closed_color</td>
              <td>event_closed_color set</td>
            </tr>
            <tr>
              <td>change_organizer</td>
              <td>change_organizer</td>
            </tr>
            <tr>
              <td>change_user</td>
              <td>change_user</td>
            </tr>
            <tr>
              <td>show_sign_up_numbers</td>
              <td>show_sign_up_numbers</td>
            </tr>
            <tr>
              <td>display_timezone</td>
              <td>display_timezone</td>
            </tr>
            <tr>
              <td>get_channel_config</td>
              <td>get_channel_config</td>
            </tr>
            <tr>
              <td>get_event_channels</td>
              <td>get_event_channels</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Event Commands</b></td>
            </tr>
            <tr>
              <td>create_event</td>
              <td>event create</td>
            </tr>
            <tr>
              <td>remove_event</td>
              <td>event remove</td>
            </tr>
            <tr>
              <td>edit_event</td>
              <td>event edit</td>
            </tr>
            <tr>
              <td>reload_event</td>
              <td>event reload</td>
            </tr>
            <tr>
              <td>open_event</td>
              <td>event open</td>
            </tr>
            <tr>
              <td>close_event</td>
              <td>event close</td>
            </tr>
            <tr>
              <td>export</td>
              <td>event export</td>
            </tr>
            <tr>
              <td>confirm</td>
              <td>event user confirm</td>
            </tr>
            <tr>
              <td>unconfirm</td>
              <td>event user unconfirm</td>
            </tr>
            <tr>
              <td>get_hidden_users</td>
              <td>event user get_hidden_users</td>
            </tr>
            <tr>
              <td>notify</td>
              <td>event user notify</td>
            </tr>
            <tr>
              <td>signup_user</td>
              <td>event user signup</td>
            </tr>
            <tr>
              <td>signoff_user</td>
              <td>event user signoff</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Repeating Event Commands</b></td>
            </tr>
            <tr>
              <td>list_repeating_events</td>
              <td>repeating_event list</td>
            </tr>
            <tr>
              <td>remove_repeating_event</td>
              <td>repeating_event remove</td>
            </tr>
            <tr>
              <td>edit_repeating_event</td>
              <td>repeating_event edit</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Gear Commands</b></td>
            </tr>
            <tr>
              <td>contribute_gears</td>
              <td>gears user contribute</td>
            </tr>
            <tr>
              <td>get_my_gears</td>
              <td>gears user amount</td>
            </tr>
            <tr>
              <td>consume_gears</td>
              <td>gears guild consume</td>
            </tr>
            <tr>
              <td>get_my_guilds</td>
              <td>gears guild share</td>
            </tr>
            <tr>
              <td>get_guild_gears</td>
              <td>gears guild get</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Patreon Commands</b></td>
            </tr>
            <tr>
              <td>set_patreon_server</td>
              <td>patreon_server set</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Server Commands</b></td>
            </tr>
            <tr>
              <td>get_date_format</td>
              <td>date_format get</td>
            </tr>
            <tr>
              <td>set_date_format</td>
              <td>date_format set</td>
            </tr>
            <tr>
              <td>get_timezone</td>
              <td>timezone get</td>
            </tr>
            <tr>
              <td>set_timezone</td>
              <td>timezone set</td>
            </tr>
            <tr>
              <td>get_timeformat</td>
              <td>timeformat get</td>
            </tr>
            <tr>
              <td>set_timeformat</td>
              <td>timeformat set</td>
            </tr>
            <tr>
              <td>get_language</td>
              <td>language get</td>
            </tr>
            <tr>
              <td>set_language</td>
              <td>language set</td>
            </tr>
            <tr>
              <td>get_server_config</td>
              <td>get_server_config</td>
            </tr>
            <tr>
              <td>reset_server</td>
              <td>reset_server</td>
            </tr>
            <tr>
              <td>whitelist_bot</td>
              <td>whitelist_bot</td>
            </tr>
            <tr>
              <td>blacklist_bot</td>
              <td>blacklist_bot</td>
            </tr>
            <tr>
              <td>whitelist_webhook</td>
              <td>whitelist_webhook</td>
            </tr>
            <tr>
              <td>blacklist_webhook</td>
              <td>blacklist_webhook</td>
            </tr>
            <tr>
              <td>limits</td>
              <td>limits</td>
            </tr>
            <tr>
              <td>set_moderator_role</td>
              <td>set_moderator_role</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Stuff Commands</b></td>
            </tr>
            <tr>
              <td>beta</td>
              <td>beta</td>
            </tr>
            <tr>
              <td>discord</td>
              <td>discord</td>
            </tr>
            <tr>
              <td>help</td>
              <td>help</td>
            </tr>
            <tr>
              <td>homepage</td>
              <td>homepage</td>
            </tr>
            <tr>
              <td>invite</td>
              <td>invite</td>
            </tr>
            <tr>
              <td>patreon</td>
              <td>patreon</td>
            </tr>
            <tr>
              <td>paypal</td>
              <td>paypal</td>
            </tr>
            <tr>
              <td>premium</td>
              <td>premium</td>
            </tr>
            <tr>
              <td>statistics</td>
              <td>statistics</td>
            </tr>
            <tr>
              <td>vote</td>
              <td>vote</td>
            </tr>
            <tr>
              <td style="color: blue; text-align:center" colspan="2"><b>Template Commands</b></td>
            </tr>
            <tr>
              <td>create_template</td>
              <td>template create</td>
            </tr>
            <tr>
              <td>edit_template</td>
              <td>template edit</td>
            </tr>
            <tr>
              <td>export_template</td>
              <td>template export</td>
            </tr>
            <tr>
              <td>get_template</td>
              <td>template get</td>
            </tr>
            <tr>
              <td>import_template</td>
              <td>template import </td>
            </tr>
            <tr>
              <td>list_templates</td>
              <td>template list</td>
            </tr>
            <tr>
              <td>remove_template</td>
              <td>template remove</td>
            </tr>
        </table>
      </div>
    </div>
  </div>

  
  
  
</section>
