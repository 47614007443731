import { Component, OnInit} from '@angular/core';
import { faDiscord, faPatreon, faPaypal } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.css']
})

export class PremiumComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }

  faIcons = {
    patreon: faPatreon,
    discord: faDiscord,
    paypal: faPaypal,
    check: faCheck,
    cross: faTimes,
    info: faInfoCircle
  };
}
