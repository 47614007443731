<div class="container-fluid">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <h1>Serverwide Settings</h1>
    </div>
  </div>
  <div *ngIf="guild" class="pb-3 pt-2">
    <div *ngIf="!guild.owner" class="alert alert-warning h5">
      &#x26A0; Only the <a href="https://docs.raidorganizer.org/getting-started/terms#server-owner" target="_blank">server owner</a>,
      <a href="https://docs.raidorganizer.org/getting-started/terms#administrator" target="_blank">administrators</a> or
      <a href="https://docs.raidorganizer.org/getting-started/terms#moderator-role" target="_blank">moderators</a> of the server
      can change those settings!
    </div>

    <ng-container *ngIf="serverInfo$ | async as serverInfo">
      <h4>Role Settings</h4>
      <div class="row">
        <div class="col">
          <form class="moderatorRoleSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label for="moderatorRoleSelection">Moderator role</mat-label>
              <mat-select
                      [(ngModel)]="serverInfo.moderator_role"
                      name="organizerRole"
                      id="moderatorRoleSelection"
                      [disabled]="!guild.owner"
                      (selectionChange)="setModeratorRole($event)"
                      placeholder="Select a role"
                      >
                <ng-container *ngFor="let role of serverInfo.roles">
                  <mat-option [value]="role.id"
                          [disabled]="role.name === '@everyone'" [selected]="role.id === serverInfo.moderator_role">
                    {{ role.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <div class="mat-select-link-wrapper">
                <a href="https://docs.raidorganizer.org/getting-started/terms#moderator-role" target="_blank" class="mat-select-link">What is the Moderator Role?</a>
              </div>
            </mat-form-field>
          </form>
        </div>
      </div>

      <h4 class="mt-3">Date and Time Settings</h4>
      <div class="row">
        <div class="col">
          <form class="dateFormatInput">
            <mat-form-field class="full-width-container">
              <mat-label>Date Format</mat-label>
              <input matInput #dateFormat maxlength="10" placeholder="YYYY-MM-DD" [value]="serverInfo.selected_date_format" [disabled]="!guild.owner" (change)="setDateFormat($event.target)">
              <mat-hint align="end">{{dateFormat.value.length}} / 10</mat-hint>
            </mat-form-field>
          </form>
        </div>

        <div class="col">
          <form class="timeFormatSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label>Time Format</mat-label>
              <mat-select [(ngModel)]="serverInfo.timeformat" name="timeFormat" [disabled]="!guild.owner" (selectionChange)="setTimeFormat($event.value)">
                <mat-option *ngFor="let timeformat of ['12h', '24h']" [value]="timeformat" [selected]="timeformat === serverInfo.timeformat">
                  {{ timeformat }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>

        <div class="col">
          <form class="timeZoneSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label for="timeZoneSelection">Time zone</mat-label>
              <mat-select [(ngModel)]="serverInfo.timezone" name="timezone" id="timeZoneSelection" [disabled]="!guild.owner" (selectionChange)="setTimeZone($event.value)" placeholder="Select a timezone">
                <mat-option *ngFor="let timezone of timezones" [value]="timezone">
                  {{ timezone }}
                </mat-option>
              </mat-select>
              <a *ngIf="guild.owner" class="form-text mat-select-link" href="javascript: void(0);" (click)="guessTimezone($event)">
                Let me guess
              </a>
            </mat-form-field>
          </form>
        </div>
      </div>

      <h4 class="mt-3">Language Settings</h4>
      <div class="row">
        <div class="col">
          <form class="LanguageSelection">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label for="LanguageSelection">Language</mat-label>
              <mat-select [(ngModel)]="serverInfo.locale" name="language" id="LanguageSelection" [disabled]="!guild.owner" (selectionChange)="setLanguage($event.value)">
                <mat-option *ngFor="let language of serverInfo.languages" [value]="language" [selected]="language === serverInfo.locale">
                  {{ language }}
                </mat-option>
              </mat-select>
              <div class="mat-select-link-wrapper">
                <a href="https://discord.gg/9JheGp8fVY" target="_blank" class="mat-select-link">
                  Help us translate
                </a>
              </div>
            </mat-form-field>
          </form>
        </div>
      </div>

      <ng-container *ngIf="serverInfo.custom_templates && serverInfo.custom_templates.length > 0">
        <h4 class="mt-3">Custom Signup Templates</h4>
        <div class="row">
          <div class="col">
            <table mat-table [dataSource]="serverInfo.custom_templates" class="mat-elevation-z8">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Template Name </th>
                <td mat-cell *matCellDef="let template"> {{template.name}} </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let template">
                  <button mat-icon-button (click)="removeTemplate(template.identifier)" [disabled]="!guild.owner">
                    <fa-icon [icon]="faIcons.trash"></fa-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['name', 'actions']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['name', 'actions'];"></tr>
            </table>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
