import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { EmbedBuilderComponent } from '../embed-builder/embed-builder.component';
import { DiscordEmbed, DiscordEmoji, EmbedField } from '../embed-builder/embed.model';
import { EmbedService } from 'src/app/services/embed.service';
import { BotGuild, CustomEmoji, CustomSignupTemplate, SignupTemplateEventTemplateUsage, SignupTemplateRepeatingEventUsage } from 'src/app/models/bot/guild';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faBars, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GuildService } from 'src/app/services/guild.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignupTemplateEventUsage } from 'src/app/models/bot/guild';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface generalSignupLimits {
  key: string;
  longName: string;
  value: number;
}

@Component({
  selector: 'app-signup-template-builder',
  templateUrl: './signup-template-builder.component.html',
  styleUrls: ['./signup-template-builder.component.css', '../embed-builder/embed-builder.component.css']
})
export class SignupTemplateBuilderComponent extends EmbedBuilderComponent implements OnInit {
  guild: BotGuild | undefined;
  emojiForm: FormGroup;
  initialFormValue: any;
  isFormDirty: boolean = false;
  isBreakingChange: boolean = false;
  @Input() signupTemplate: CustomSignupTemplate;
  @Input() serverCustomEmojis: CustomEmoji[];
  showEmojiPicker = false;
  signupConfig: DiscordEmbed = {}
  faIcons = {
    trash: faTrashAlt,
    bars: faBars,
  };
  private subscriptions = new Map<string, Subscription>();
  signupTemplateId: string;
  guildId: string;
  generalSignupLimits: generalSignupLimits[] = [
    {
      key: 'max_sign_ups_total',
      longName: 'Maximum sign ups to event',
      value: 0
    },
    {
      key: 'max_sign_ups_per_user',
      longName: 'Sign ups allowed per user',
      value: 1,
    },
    {
      key: 'max_sign_ups_per_role',
      longName: 'Maximum sign ups for all "role" emojis',
      value: 0
    },
    {
      key: 'max_sign_ups_per_status',
      longName: 'Maximum sign ups for all "status" emojis',
      value: 0
    }
  ]
  dialogEditTemplateRef: MatDialogRef<any>;
  dialogDeleteTemplateRef: MatDialogRef<any>;
  @ViewChild('editSignupTemplateDangerDialog') editSignupTemplateDangerDialog: TemplateRef<any>; // Reference to the dialog template
  @ViewChild('deleteSignupTemplateDangerDialog') deleteSignupTemplateDangerDialog: TemplateRef<any>; // Reference to the dialog template
  showConfirmButtons: boolean = false;
  resetSignal: boolean = false; // Reset signal for emoji picker
  isDesktop: boolean;

  constructor(
    embedService: EmbedService,
    private fb: FormBuilder,
    public guildService: GuildService,
    private botService: BotResourceService,
    private route: ActivatedRoute,
    private router: Router,
    public dialogTempalte: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    super(embedService);
  }

  ngOnInit(): void {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });
    this.signupTemplateId = this.route.snapshot.paramMap.get('signupTemplateId');
    this.breakpointObserver.observe(["(max-width: 720px)"]).subscribe(result => {
      this.isDesktop = !result.matches;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Initialize the service state with the signup specific configuration
    // print content of signup template
    // this.initializeSignupTemplate();
    this.signupTemplateId = this.route.snapshot.paramMap.get('signupTemplateId');

    this.initForm();
    this.signupTemplateToEmbed();
    this.addSubscriptions(this.emojiForm);

    this.setupChangeDetection();

  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  private unsubscribeAll() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions.clear();
  }

  get emojis(): FormArray {
    return this.emojiForm.get('emojis') as FormArray;
  }

  initForm() {
    this.emojiForm = this.fb.group({
      emojis: this.fb.array([]),
      generalLimits: this.fb.group(
        this.generalSignupLimits.reduce((acc, limit) => ({
          ...acc,
          [limit.key]: [limit.value, [Validators.min(0), Validators.pattern("^[0-9]*$"), Validators.required]]
        }), {})
      ),
      signupTemplateTitle: [this.signupTemplate.name, [Validators.required, Validators.maxLength(25), Validators.minLength(1)]],
      signupTemplateIcon: [this.signupTemplate.icon, [Validators.required]],
      signupTemplateColor: [this.signupTemplate.color, [Validators.required]],
      signupTemplateEmojiBehavior: [this.signupTemplate.emoji_behavior, [Validators.required]],
      signupTemplateEmojiTitle: [this.signupTemplate.emoji_title ?? false],
    });
    // print the generalLimits
    this.initEmojis();
    this.initLimits();
    // TODO: Load this once on init and use this again when the route changes

    this.initialFormValue = JSON.parse(JSON.stringify(this.emojiForm.value)); // Deep copy initial state

    // Touch all form fields on load. This way we can show validation errors immediately
    this.emojiForm.markAllAsTouched();
  }

  initLimits() {
    // init general limits. Emoji field limits will be initialized in initEmojis
    Object.entries(this.signupTemplate.limits).forEach(([key, value]) => {
      const control = this.emojiForm.get(['generalLimits', key]);
      if (control) {
        control.setValue(value);
      }
    });
  }

  initEmojis() {
    const emojiControls = this.emojiForm.get('emojis') as FormArray;
    Object.keys(this.signupTemplate.emojis).forEach(key => {
      const value = this.signupTemplate.emojis[key];
      const subEmojiControls: FormArray = this.fb.array([]);

      if (value.emojis) {
        Object.keys(value.emojis).forEach(subKey => {
          let subLimit = this.signupTemplate.limits[value.type + "," + value.field_name + "," + value.emojis[subKey]?.field_name] || 0;
          subEmojiControls.push(this.fb.group({
            emoji: subKey,
            fieldName: value.emojis[subKey]?.field_name,
            limit: [subLimit, [Validators.min(0), Validators.pattern("^[0-9]*$"), Validators.required]]
          }));
        });
      }

      let mainLimit = this.signupTemplate.limits[value.type + "," + value.field_name] || 0;
      emojiControls.push(this.fb.group({
        emoji: key,
        fieldName: value.field_name,
        type: value.type,
        subEmojis: subEmojiControls,
        limit: [mainLimit, [Validators.min(0), Validators.pattern("^[0-9]*$"), Validators.required]]
      }));
    });
  }

  signupTemplateToEmbed() {
    // This function converts the signup template fields to a Discord embed
    // This way the embed preview component can display the changes
    this.signupConfig.title = "Example Event Preview";
    this.signupConfig.description = '**Event Info:**  \n🗓️ < event date >  \n🕑 < event start > - < event end >'
    this.signupConfig.color = this.signupTemplate.color;
    this.signupTemplateEmojisToEmbedFields();
  }

  parseCustomEmojiForEmbed(emoji: string) {
    // This function returns an image tag with the emoji to be displayed in the embed preview
    const customEmoji = this.serverCustomEmojis.find(customEmoji => emoji.includes(customEmoji.id));
    if (customEmoji) {
      return `<img class="custom-button-image" src="${customEmoji.imageUrl}" alt="${customEmoji.name}"></img>`
    } else {
      // Emoji does not belong to this server. We try best effort to display the emoji anyway.
      // Emoji comes in format of `<a:name:id>` if anmiated, else `<name:id>`
      // We need to get the id and append it to the url `https://cdn.discordapp.com/emojis/<id>`
      // We also need to check if the emoji is animated. If it is, we append `.gif` to the url, else `.png`
      const emojiParts = emoji.match(/<a?:(\w+):(\d+)>/);
      if (emojiParts && emojiParts.length === 3) {
        const isAnimated = emoji.startsWith('<a:');
        const emojiId = emojiParts[2];
        const imageUrl = `https://cdn.discordapp.com/emojis/${emojiId}${isAnimated ? '.gif' : '.png'}`;
        return `<img class="custom-button-image" src="${imageUrl}" alt="${emojiParts[1]}"></img>`;
      }
    }
  }

  parseEmojiForReaction(emoji: string) {
    // This function returns the DiscordEmoji instance for the reaction
    const customEmoji = this.serverCustomEmojis.find(customEmoji => emoji.includes(customEmoji.id));
    if (customEmoji) {
      return {url: customEmoji.imageUrl, name: customEmoji.name, count: 1};
    } else if (emoji.includes(':') && emoji.includes('<') && emoji.includes('>')) {
      const emojiParts = emoji.match(/<a?:(\w+):(\d+)>/);
      if (emojiParts && emojiParts.length === 3) {
        const isAnimated = emoji.startsWith('<a:');
        const emojiId = emojiParts[2];
        const imageUrl = `https://cdn.discordapp.com/emojis/${emojiId}${isAnimated ? '.gif' : '.png'}`;
        return {url: imageUrl, name: emojiParts[1], count: 1};
      }
    }
    return {url: emoji, name: emoji, count: 1};
  }

  isCustomEmoji(emoji: string) {
    return emoji.includes(':') && emoji.includes('<') && emoji.includes('>')
  }

  private updateFields() {
    // This function calls everything that needs to be updated on a change
    this.signupTemplateEmojisToEmbedFields();
    this.validateEmojis()

    // this.signupTemplateEmojisToLimits();
  }

  private signupTemplateEmojisToLimits() {
    // This function updates the limits of each emoji
    (this.emojiForm.get('emojis') as FormArray).controls.forEach(control => {
      control.get('limit').setValue(control.get('subEmojis').value.length);
    });
  }

  private signupTemplateEmojisToEmbedFields() {
    const roleFields: EmbedField[] = [];
    const statusFields: EmbedField[] = [];
    const msgEmojis: DiscordEmoji[] = []; // Holds the emojis displayed at the bottom of the preview
    const isEmojiTitle = this.emojiForm.get('signupTemplateEmojiTitle').value;
    let hasStatusField = false;
    let hasRoleField = false;
    (this.emojiForm.get('emojis') as FormArray).controls.forEach(control => {
      let mainEmoji = control.get('emoji').value;
      let embedParsedMainEmoji = control.get('emoji').value;
      let value = "";
      const subEmojis = (control.get('subEmojis') as FormArray).controls;
      const fieldName = control.get('fieldName').value;

      // Parse custom emoji to image url if needed
      if (this.isCustomEmoji(mainEmoji)) {
        embedParsedMainEmoji = this.parseCustomEmojiForEmbed(mainEmoji);
      }

      // Only display top level emojis on 2 and 3 and
      // on 1 if sub emojis are present
      if (! (this.emojiForm.get('signupTemplateEmojiBehavior').value === "1" && subEmojis.length) ) {
        msgEmojis.push(this.parseEmojiForReaction(mainEmoji));
        value = `${embedParsedMainEmoji} - ${fieldName}\n`;
      }

      subEmojis.forEach(subControl => {
        let subEmoji = subControl.get('emoji').value;
        const subFieldName = subControl.get('fieldName').value;
        if (this.emojiForm.get('signupTemplateEmojiBehavior').value === "1") {
          // If behavior is 1, we also display sub emojis in the message
          msgEmojis.push(this.parseEmojiForReaction(subEmoji));
        }
        if (this.isCustomEmoji(subEmoji)) {
          subEmoji = this.parseCustomEmojiForEmbed(subEmoji);
        }
        value += `${subEmoji} - ${fieldName}, ${subFieldName}\n`;
      });

      const name = isEmojiTitle ? `${embedParsedMainEmoji} ${fieldName}` : fieldName;
      if (control.get('type').value === 'status') {
        statusFields.push({
          name: name,
          value: value.trim(),
          inline: true
        });
        hasStatusField = true;
      } else if (control.get('type').value === 'role') {
        roleFields.push({
          name: name,
          value: value.trim(),
          inline: true
        });
        hasRoleField = true
      }
    });
    this.signupConfig.fields = roleFields;
    if (hasStatusField) {
      if (hasRoleField) {
        this.signupConfig.fields.push({
          name: "",
          value: "---",
          inline: false
        })
      }
      this.signupConfig.fields.push(...statusFields);
    }
    this.signupConfig.color = this.emojiForm.get('signupTemplateColor').value;

    this.embedService.updateEmbed(this.signupConfig);
    this.embedService.updateEmojis(msgEmojis);
  }

  addEmoji() {
    const emojiControls = this.emojiForm.get('emojis') as FormArray;
    const emojiGroup = this.fb.group({
      type: ['', Validators.required],
      fieldName: ['', this.fieldNameValidator('')],
      emoji: ['', Validators.required],
      limit: [0, [Validators.min(0), Validators.pattern("^[0-9]*$"), Validators.required]],
      subEmojis: this.fb.array([])
    });
    emojiControls.push(emojiGroup);

    // Subscribe to changes on the type control
    emojiGroup.get('type').valueChanges.subscribe(value => {
      this.updateFieldNameValidator(emojiGroup, value);
    });

    this.updateFields();
  }

  fieldNameValidator(type: string) {
    // Validator function to conditionally add required validator
    return (control: AbstractControl) => {
      if (type === 'role' || type === 'status') {
        return Validators.required(control);
      }
      return null;
    };
  }

  updateFieldNameValidator(emojiGroup: FormGroup, type: string) {
    // Method to update the fieldName validators based on the type
    const fieldNameControl = emojiGroup.get('fieldName');
    if (type === 'role' || type === 'status') {
      fieldNameControl.setValidators([Validators.required]);
    } else {
      fieldNameControl.clearValidators();
    }
    fieldNameControl.updateValueAndValidity();
  }

  addSubEmoji(parentIndex: number) {
    const subEmojis = this.emojis.at(parentIndex).get('subEmojis') as FormArray;
    const subEmojiFormGroup = this.fb.group({
      fieldName: ['', Validators.required],
      emoji: ['', Validators.required],
      limit: [0, [Validators.min(0), Validators.pattern("^[0-9]*$"), Validators.required]]
    });
    subEmojis.push(subEmojiFormGroup);
    this.updateFields();
  }

  removeEmoji(index: number) {
    this.disposeSubscription(`.emojis.${index}`);
    const emojiControls = this.emojiForm.get('emojis') as FormArray;
    emojiControls.removeAt(index);
    // this.reinitializeSubscriptions(emojiControls, '.emojis');
    this.updateFields();
  }

  removeSubEmoji(parentIndex: number, index: number) {
    const subEmojis = this.emojis.at(parentIndex).get('subEmojis') as FormArray;
    subEmojis.removeAt(index)
    this.updateFields();
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  closeEmojiPicker() {
    // Use setTimeout to delay the closure to allow for emoji selection
    setTimeout(() => this.showEmojiPicker = false, 200);
  }

  extractEmojiId(emoji: string): string {
    // emoji can be both just `<a:3042loading:839545303287595058>` or the whole url
    // <img class="custom-button-image" src="https://cdn.discordapp.com/emojis/839545303287595058.gif" alt="<a:3042loading:839545303287595058>">
    // It can also be saved as either in the form.
    // The machting should handle that.
    if (!emoji) {
      return '';
    }

    const shortEmojiMatch = emoji.match(/<a?:\w+:(\d+)>/);
    if (shortEmojiMatch) {
      return shortEmojiMatch[1];
    }

    const urlMatch = emoji.match(/src="https:\/\/cdn\.discordapp\.com\/emojis\/(\d+)\./);
    if (urlMatch) {
      return urlMatch[1];
    }

    return emoji; // for standard Unicode emojis, return the emoji itself
  }

  isSubEmojiDuplicate(groupIndex: number, emoji: string): boolean {
    const subEmojisArray = this.emojis.at(groupIndex).get('subEmojis') as FormArray;
    const subEmojis = subEmojisArray.controls.map(control => control.get('emoji').value);
    const emojiId = this.extractEmojiId(emoji);
    return subEmojis.some(e => this.extractEmojiId(e) === emojiId);
  }

  isEmojiDuplicate(emoji: string): boolean {
    const emojis = this.emojis.controls.map(control => control.get('emoji').value);
    const emojiId = this.extractEmojiId(emoji);
    return emojis.some(e => this.extractEmojiId(e) === emojiId);
  }

  getTopEmojis(): string[] {
    return this.emojis.controls.map(control => control.get('emoji').value).filter(value => value);
  }

  getSubEmojis(): string[] {
    return this.emojis.controls.reduce((acc, control) => {
      const subEmojisArray = control.get('subEmojis') as FormArray;
      return acc.concat(subEmojisArray.controls.map(subControl => subControl.get('emoji').value));
    }, []).filter(value => value);
  }

  isEmojiUsed(emoji: string): number {
    const topEmojis = this.getTopEmojis();
    const subEmojis = this.getSubEmojis();
    const emojiId = this.extractEmojiId(emoji);
    const topEmojiCount = topEmojis.filter(e => this.extractEmojiId(e) === emojiId).length;
    const subEmojiCount = subEmojis.filter(e => this.extractEmojiId(e) === emojiId).length;
    return topEmojiCount + subEmojiCount;
  }

  handleEmojiSelection(control: any, emoji: string, groupIndex: number, isSubEmoji: boolean): void {
    const emojiBehavior = this.emojiForm.get('signupTemplateEmojiBehavior').value;
    if (emojiBehavior === "2" || emojiBehavior === "3") {
      if (isSubEmoji && this.isSubEmojiDuplicate(groupIndex, emoji)) {
        alert('This emoji is already used in this sub-emoji group.');
        this.triggerResetSignal();
      } else if (!isSubEmoji && this.isEmojiDuplicate(emoji)) {
        alert('This emoji is already used as a main emoji.');
        this.triggerResetSignal();
      } else {
        control.setValue(emoji);
      }
    } else if (emojiBehavior === "1") {
      if (this.isEmojiUsed(emoji)) {
        alert('This emoji is already used.');
        this.triggerResetSignal();
      } else {
        control.setValue(emoji);
      }
    }
  }

  triggerResetSignal() {
    this.resetSignal = true;
    setTimeout(() => {
      this.resetSignal = false;
    }, 0);
  }

  private addSubscriptions(formGroup: FormGroup | FormArray, path = '') {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      const controlPath = `${path}.${key}`;

      // If it's a FormGroup or FormArray, recursively subscribe to its children
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.addSubscriptions(control, controlPath);
      }

      if (control instanceof FormControl) {
        if (!this.subscriptions.has(controlPath)) {
          const sub = control.valueChanges.subscribe(value => {
            this.updateFields();
            // Call your update function if necessary
          });
          this.subscriptions.set(controlPath, sub);
        }
      }
    });

    // Listen for changes in the structure of FormArray
    if (formGroup instanceof FormArray) {
      const sub = formGroup.valueChanges.subscribe(() => this.reinitializeSubscriptions(formGroup, path));
      this.subscriptions.set(path, sub);
    }
  }

  private reinitializeSubscriptions(formArray: FormArray, path: string) {
    // Clear existing subscriptions for this path
    formArray.controls.forEach((_, index) => {
      const controlPath = `${path}.${index}`;
      this.disposeSubscription(controlPath);
      this.addSubscriptions(formArray.at(index) as FormGroup, controlPath);
    });
  }

  private disposeSubscription(path: string) {
    this.subscriptions.forEach((sub, key) => {
      if (key.startsWith(path)) {
        sub.unsubscribe();
        this.subscriptions.delete(key);
      }
    });
  }

  setupChangeDetection() {
    this.emojiForm.valueChanges.subscribe(() => {
      const form_valid = this.checkFormValidity();
      this.isFormDirty = form_valid && JSON.stringify(this.emojiForm.value) !== JSON.stringify(this.initialFormValue);
      this.isBreakingChange = form_valid && this.form_breaking_field_changed();
    });
  }

  checkFormValidity(): boolean {
    const requiredFieldsValid = this.emojiForm.get('signupTemplateTitle')!.valid &&
                                this.emojiForm.get('signupTemplateIcon')!.valid &&
                                this.emojiForm.get('signupTemplateColor')!.valid &&
                                this.emojiForm.get('signupTemplateEmojiBehavior')!.valid;
    const atLeastOneEmoji = this.emojis.length > 0 && this.emojis.controls.some(ctrl => ctrl.valid);
    return requiredFieldsValid && atLeastOneEmoji;
  }

  form_breaking_field_changed(): boolean {
    // Returns true if either `emojis` or `emoji_behavior` changed
    return JSON.stringify(this.emojiForm.value.emojis) !== JSON.stringify(this.initialFormValue.emojis) ||
           this.emojiForm.get('signupTemplateEmojiBehavior')!.value !== this.initialFormValue.signupTemplateEmojiBehavior
  }

  saveTemplate() {
    this.isFormDirty = false
    if (this.signupTemplateId === null) {
      this.botService.createSignupTemplate(this.guildId, this.emojiForm.value).subscribe({
        next: (response: any) => {
          if (response.status === 201) {
            const templateId = response.body.id;
            const newTemplatePath = `/guilds/${this.guildId}/signup-template/${templateId}`;
            this.guild.signup_templates.push({
              id: templateId,
              name: response.body.name
            });
            this.router.navigate([newTemplatePath]); // Navigate to the new URL
          }
        },
        error: (error: any) => {
          console.error('Error creating template:', error);
          this.isFormDirty = true
          // Add error handling logic here, like showing an error message
        }
      });
    } else {
      if (this.isBreakingChange) {
        // open danger dialog, but with confirm and cancel buttons
        this.showConfirmButtons = true;
        this.openEditSignupTempalteDangerDialog();
      } else {
        this.updateTemplate(false);
      }
    }
  }

  deleteTemplate() {
    if (this.signupTemplate.used_by) {
      this.openDeleteSignupTempalteDangerDialog();
    } else {
      this.deleteTemplateConfirmed();
    }
  }

  openDeleteSignupTempalteDangerDialog() {
    this.dialogDeleteTemplateRef = this.dialogTempalte.open(this.deleteSignupTemplateDangerDialog);

    this.dialogDeleteTemplateRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the action if "Continue" is clicked in the dialog
        this.deleteTemplateConfirmed();
    } else {
        // Handle the action if "Cancel" is clicked in the dialog
      }
    });
  }

  deleteTemplateConfirmed() {
    this.botService.deleteSignupTemplate(this.guildId, this.signupTemplateId).subscribe({
      next: () => {
        this.guild.signup_templates = this.guild.signup_templates.filter(template => template.id !== this.signupTemplateId);
        this.router.navigate([`/guilds/${this.guildId}/signup-template/new`]);
      },
      error: (error: any) => {
        console.error('Error deleting template:', error);
        // Add error handling logic here, like showing an error message
      }
    });
  }

  confirmDeleteAction() {
    this.dialogDeleteTemplateRef.close(true);
  }

  updateTemplate(idChange: boolean) {
    this.botService.postSignupTemplate(this.guildId, this.signupTemplateId, this.emojiForm.value, idChange).subscribe({
      next: (result) => {
        this.initialFormValue = JSON.parse(JSON.stringify(this.emojiForm.value)); // Deep copy initial state
        // find signup template with id in list and update it's name
        this.guild.signup_templates = this.guild.signup_templates.map(template => {
          if (template.id === this.signupTemplateId) {
            template.name = this.emojiForm.get('signupTemplateTitle').value;
          }
          return template;
        });
        this.signupTemplate = result
        this.isFormDirty = false
      },
      error: (err) => {
        this.isFormDirty = true
      }
    });
  }

  openEditSignupTempalteDangerDialog(): void {
    this.dialogEditTemplateRef = this.dialogTempalte.open(this.editSignupTemplateDangerDialog);

    this.dialogEditTemplateRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the action if "Continue" is clicked in the dialog
        this.updateTemplate(true);
        this.confirmEditAction();
      } else {
        // Handle the action if "Cancel" is clicked in the dialog
        if (this.showConfirmButtons) {
          this.showConfirmButtons = false;
          this.isFormDirty = true;
        }
      }
    });
  }

  confirmEditAction(): void {
    this.dialogEditTemplateRef.close(true);
  }


  getEventEntries(): SignupTemplateEventUsage[] {
    return this.signupTemplate.used_by?.events
      ? Object.values(this.signupTemplate.used_by.events)
      : [];
  }

  getRepeatingEventEntries(): SignupTemplateRepeatingEventUsage[] {
    return this.signupTemplate.used_by?.repeating_events
      ? Object.values(this.signupTemplate.used_by.repeating_events)
      : [];
  }

  getEventTemplateEntries(): SignupTemplateEventTemplateUsage[] {
    return this.signupTemplate.used_by?.event_templates
      ? Object.values(this.signupTemplate.used_by.event_templates)
      : [];
  }

  isTypeSelected(type: string): boolean {
    return this.emojis.controls.some(control => control.get('type')?.value === type);
  }

  validateEmojis() {
    const emojiBehavior = this.emojiForm.get('signupTemplateEmojiBehavior').value;
    let emojiCount = 0;

    this.emojis.controls.forEach((group, groupIndex) => {
      const subEmojisArray = group.get('subEmojis') as FormArray;
      const topEmoji = group.get('emoji').value;
      emojiCount += 1;

      // Validate top-level emoji
      if (emojiBehavior === "1" && this.isEmojiUsed(topEmoji) > 1) {
        group.get('emoji').setErrors({ duplicate: true });
      } else if (emojiBehavior === "1" && emojiCount > 20) {
        group.get('emoji').setErrors({ emojiLimit: true });
      } else {
        group.get('emoji').setErrors(null);
      }

      // Validate sub-emojis
      let subEmojiCount = 0;
      subEmojisArray.controls.forEach((control, subIndex) => {
        if (emojiBehavior === "1") {
          emojiCount += 1;
        } else {
          subEmojiCount += 1;
        }
        const subEmoji = control.get('emoji').value;
        if (emojiBehavior === "1" && this.isEmojiUsed(subEmoji) > 1) {
          control.get('emoji').setErrors({ duplicate: true });
        } else if (emojiBehavior === "1" && emojiCount > 20) {
          control.get('emoji').setErrors({ emojiLimit: true });
        } else if (emojiBehavior !== "1" && subEmojiCount > 20) {
          control.get('emoji').setErrors({ subEmojiLimit: true });
        } else {
          control.get('emoji').setErrors(null);
        }
      });
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.emojis.controls, event.previousIndex, event.currentIndex);
    this.emojis.updateValueAndValidity();
    this.updateFields()
  }

  dropSubEmoji(event: CdkDragDrop<string[]>, groupIndex: number) {
    const subEmojis = this.emojis.at(groupIndex).get('subEmojis') as FormArray;
    moveItemInArray(subEmojis.controls, event.previousIndex, event.currentIndex);
    subEmojis.updateValueAndValidity();
    this.updateFields()
  }
}
