import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { BotGuild, GearInfo } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';

interface GearContribution {
  value: number;
  id: string;
  viewValue: string;
}

@Component({
  selector: 'app-gear-card',
  templateUrl: './gear-card.component.html',
  styleUrls: ['./gear-card.component.css']
})
export class GearCardComponent implements OnInit {
  faIcons = {
    gear : faCog,
  };

  guildId: string;
  guild: BotGuild;

  @Input() gearInfo$: Observable<GearInfo>;

  features: GearContribution[] = [
    { value: 15, id: 'add_repeating_event', viewValue: '(15 Gears) + 1 Max. repeating events' },
    { value: 15, id: 'add_channel', viewValue: '(15 Gears) + 1 Max. event channels' },
    { value: 25, id: 'add_event_template', viewValue: '(25 Gears) + 1 Max. event template' },
    { value: 75, id: 'add_signup_template', viewValue: '(75 Gears) + 1 Max. signup template' },
    { value: 25, id: '1_week_premium', viewValue: '(25 Gears) + 1 Premium week' },
    { value: 75, id: '4_weeks_premium', viewValue: '(75 Gears) + 4 Premium weeks' },
  ];
  selectedFeature: GearContribution;
  reminderOptions: number[] = [1, 2, 3, 4, 5, 6];
  premiumReminder: number;

  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
  ) { }

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');

    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });

    this.gearInfo$ = this.botService.getGuildGear(this.guildId).pipe(
      shareReplay(1)
    );
    this.gearInfo$.subscribe(gearInfo => {
      // console.log(gearInfo);
    });

  }

  onGearUserInput(input: HTMLInputElement) {
    const newValue = +input.value; // Convert the input value to a number
    let max = 0; // Get the maximum value from the channelInfo Observable
    this.gearInfo$.subscribe(gearInfo => {
      max = gearInfo.user.curr_gears;
    });

    // Subscribe to the channelInfo Observable to get the current value
    this.gearInfo$.pipe(take(1)).subscribe((gearInfo) => {
      // Check if the new value exceeds the maximum
      if (newValue > max) {
        // Reset the value to the previous value
        input.value = (gearInfo.user.curr_gears).toString();
      }
    });
  }

  contributeGears(nr: number) {
    // console.log(nr);
    this.botService.sendCommand(this.guildId, 'gears user contribute', nr.toString(), null, () => {
      this.gearInfo$.subscribe(gearInfo => {
        gearInfo.user.curr_gears -= nr;
        gearInfo.guild.curr_gears += nr;
      });
    });
  }

  consumeGears(contribution: GearContribution) {
    this.botService.gearExchange(this.guildId, contribution.viewValue, contribution.id, this.premiumReminder, () => {
      this.gearInfo$.subscribe(gearInfo => {
        gearInfo.guild.curr_gears -= contribution.value;
        if (contribution.id === 'add_repeating_event')
          gearInfo.limits.max_repeating_events += 1;
        else if (contribution.id === 'add_channel')
          gearInfo.limits.max_event_channels += 1;
        else if (contribution.id === 'add_signup_template')
          gearInfo.limits.max_custom_templates += 1;
        else if (contribution.id === 'add_event_template')
          gearInfo.limits.max_event_templates += 1;
        else if (contribution.id.includes('premium'))
          this.guild.has_premium = true;
      });
    });
  }

  isReminderEnabled(): boolean {
    return (
      this.selectedFeature &&
      (this.selectedFeature.id === '1_week_premium' || this.selectedFeature.id === '4_weeks_premium')
    );
  }
}
