import { ChangeDetectorRef, Component, Input, OnInit, Renderer2,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BotGuild } from 'src/app/models/bot/guild';
import { GuildService } from 'src/app/services/guild.service';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';

interface AdsPlacement {
  name: string;
  description: string;
  image: string;
  url: string;
}

@Component({
  selector: 'app-guild-dashboard',
  templateUrl: './guild-dashboard.component.html',
  styleUrls: ['./guild-dashboard.component.css']
})
export class GuildDashboardComponent implements OnInit {
  faIcon = {
    menubar: faBars,
    add: faPlus,
  };

  channelSelected = false;

  ads_nr = 0;

  ads: AdsPlacement[] = [
    {
      name: 'Proton Mail',
      description: 'Proton is a safe and privacy first swiss email, vpn, calendar, password manager and drive service.<br><br>This is also where I host my emails and it\'s the vpn of my choice!',
      image: '/assets/images/proton_mail.png',
      url: 'https://go.getproton.me/SHg6'
    },
    {
      name: 'Proton VPN',
      description: 'Proton is a safe and privacy first swiss email, vpn, calendar, password manager and drive service.<br><br>This is also where I host my emails and it\'s the vpn of my choice!',
      image: '/assets/images/proton_vpn.png',
      url: 'https://go.getproton.me/SHg8'
    },
    // {
    //   name: 'Proton Drive',
    //   description: 'Proton is a safe and privacy first swiss email, vpn, calendar, password manager and drive service.<br><br>This is also where I host my emails and it\'s the vpn of my choice!',
    //   image: '/assets/images/proton_drive.svg',
    //   url: 'https://go.getproton.me/SHg9'
    // },
    {
      name: 'Hetzner',
      description: 'Hetzner is a German hosting company that offers a wide range of services.<br>I use their very reliable VPS for all my projects (including Raid Organizer) and their Cloud Storage services.<br><br>Click the banner to get 20€ worth of credits.',
      image: '/assets/images/hetzner.svg',
      url: 'https://hetzner.cloud/?ref=eJn45HQkudQl'
    },
    {
      name: 'UptimeRobot',
      description: 'UptimeRobot is a free service that monitors your services every 5 minutes and notifies you if your service is down.<br>I use it to monitor all my projects, including Raid Organizer.',
      image: '/assets/images/uptimerobot.svg',
      url: 'https://uptimerobot.com/?rid=82613b2c2b76a4'
    },
    // {
    //   name: 'Netlify',
    //   description: 'Netlify is a hosting service for static websites. This service makes it very easy to host your website.<br>I use it to host all Raid Organizer related websites.',
    //   image: '',
    //   url: ''
    // },
    {
      name: 'Hover',
      description: 'Hover is a domain name registrar. All my domains (including <a href="https://raidorganizer.org" target="_blank">raidorganizer.org</a>) are registered there.<br><br>Get 2$ off your first purchase and support me by clicking the banner.',
      image: '/assets/images/hover.svg',
      url: 'https://hover.com/fxcG33B9'
    },
    {
      name: 'Humblebundle',
      description: 'Humblebundle is a charity website where you can buy game, book and software bundles for a very cheap price. They work together with publishers to 1) offer bundles cheaper and 2) support a charity with those earnings.<br><br>I buy a lot of my IT books there.',
      image: '/assets/images/humblebundle.svg',
      url: 'https://www.humblebundle.com/?partner=snawe'
    },
  ]


  guild: BotGuild | undefined;
  raidOrganizerGuild: BotGuild | undefined;
  id: string;
  showDescription: boolean = false;
  onImage: boolean = false;
  onDescription: boolean = false;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public guildService: GuildService,
    private observer: BreakpointObserver,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.guildService.getGuildData(this.id).subscribe(guild => {
      this.guild = guild;
    });
    this.raidOrganizerGuild = this.guildService.raidOrganizerGuild;
    this.ads_nr = Math.floor(Math.random() * this.ads.length);
    this.channelSelected = this.router.url.includes("channel/");
  }

  ngAfterViewInit() {
    if (this.sidenav) {
      this.observer.observe(["(max-width: 720px)"]).subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = "over";
          this.sidenav.close();
        } else {
          this.sidenav.mode = "side";
          this.sidenav.open();
        }
        // NOTE: We need to call detectChanges() here because with the observer we are chaning the sidenav mode
        //  after the Angular change detection cycle has completed. This results in the error:
        //  ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        //  By calling it here we are telling Angular to run another change detection cycle.
        //  Addition: This has some performance implications.
        this.cdr.detectChanges();
      });
      // NOTE: This removes the scrollbar from the guild-dashboard sidenav.
      // This only works on Firefox. Thats why there is also a logic in the styles.css
      this.renderer.setStyle(this.sidenav._content.nativeElement,  'scrollbar-width', 'none');
      // Handle sidenav closing on route change
      this.router.events.subscribe((event) => {
        if (this.sidenav.mode === 'over' && this.sidenav.opened) {
          this.sidenav.close();
        }
      });
    }
  }

  hideDescription() {
    if (this.onDescription || this.onImage) {
      return;
    }
    this.showDescription = false;
  }

  enterImage() {
    this.onImage = true;
    this.showDescription = true;
  }

  enterDescription() {
    this.onDescription = true;
    this.showDescription = true;
  }

  leaveDescription() {
    setTimeout(() => {
      this.onDescription = false;
      this.hideDescription();
    }, 200);
  }

  leaveImage() {
    setTimeout(() => {
      this.onImage = false;
      this.hideDescription();
    }, 200);
  }

  goBack(): void {
    this.location.back();
  }
}
