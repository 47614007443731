import { Injectable } from '@angular/core';
import { PermissionValue } from '../models/discord-api/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  public hasPermission(permissions: number, permission: PermissionValue): boolean {
    // tslint:disable-next-line:no-bitwise
    return (permissions & permission) === permission;
  }

  getPermissions(permissions: number): string[] {
    const result: string[] = [];

    for (const key in PermissionValue) {
      if (isNaN(Number(key)) && this.hasPermission(permissions, PermissionValue[key as keyof PermissionValue])) {
        result.push(key);
      }
    }

    return result;
  }

  getBotAccessPermissions(): number {
    return this.buildPermissionValue([
      PermissionValue.ADD_REACTIONS,
      PermissionValue.SEND_MESSAGES,
      PermissionValue.MANAGE_MESSAGES,
      PermissionValue.EMBED_LINKS,
      PermissionValue.READ_MESSAGE_HISTORY,
    ]);
  }

  buildPermissionValue(permissions: PermissionValue[]): number {
    // tslint:disable-next-line:no-bitwise
    return permissions.reduce((acc, p) => acc | p);
  }
}
