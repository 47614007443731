<div class="table-responsive-lg">
  <table class="table table-striped table-dark">
    <thead class="thead-dark">
    <tr class="d-flex">
      <th scope="col" class="col-2">Command</th>
      <th scope="col" class="col-4">Description</th>
      <th scope="col" class="col-2">Usage</th>
      <th scope="col" class="col-4">Help</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let command of commands" class="d-flex">
      <td class="col-2">
        <code>{{ '/' + command.command }}</code>
      </td>
      <td class="col-4">
        <app-markdown [text]="command.description"></app-markdown>
      </td>
      <td class="col-2">
        <div>{{ '/' + command.usage }}</div><br>
        <div>
          <strong>example: </strong>
          <code>{{ '/' + command.example }}</code>
        </div>
      </td>
      <td class="col-4">
        <app-markdown [text]="command.help"></app-markdown>
      </td>
    </tr>
    </tbody>
  </table>
</div>
