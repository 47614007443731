<header class="site-header">
  <nav class="navbar navbar-expand-lg navbar-dark"> <!-- fixed-top -->
    <button class="navbar-toggler" data-toggle="collapse" data-target="#navbarToggler"
            aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand" routerLink="/">
      <img class="rounded-circle mr-2 d-none d-sm-inline"
          src="https://cdn.discordapp.com/avatars/658698521570181140/6f0359e5b55b249b959cddbf2feb5d52.png?size=32" />
      <span class="mr-2">Raid Organizer</span>
      <span class="badge rounded-pill text-bg-light d-none d-sm-inline-block" *ngIf="!environment.production">BETA</span>
    </a>

    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-1">
        <li class="nav-item">
          <a routerLink="/" class="nav-link" data-toggle="collapse" data-target="#navbarToggler.show">
            Home
          </a>
        </li>
        <li class="nav-item">
          <a href="https://docs.raidorganizer.org" target="_blank" class="nav-link"  data-target="#navbarToggler.show">
            Documentation
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="help" class="nav-link" data-toggle="collapse" data-target="#navbarToggler.show">
            Commands
          </a>
        </li>
        <li class="nav-item">
          <button class="btn btn-outline-danger nav-item mb-2 mr-2 fit"
                  data-toggle="collapse" data-target="#navbarToggler.show" routerLink="premium">
            Premium
          </button>
        </li>
        <li class="nav-item">
          <button *ngIf="isLoggedIn" class="btn btn-outline-success nav-item mb-2 mb-md-0 fit"
                  data-toggle="collapse" data-target="#navbarToggler.show" routerLink="guilds">
          Manage Servers
          </button>
        </li>
      </ul>
    </div>

    <div class="align-right text-white mr-3">
      <!-- <app-dark-mode-toggle></app-dark-mode-toggle> -->
    </div>

    <div class="align-right">
      <button *ngIf="!isLoggedIn" class="btn btn-outline-primary btn-login-custom" (click)="login()">Login & Join</button>
      <button *ngIf="isLoggedIn" class="btn btn-outline-secondary" (click)="logout()">Logout</button>
      <img *ngIf="isLoggedIn" class="rounded-circle ml-2 d-none d-sm-inline" [attr.src]="avatarSrc" />
    </div>
  </nav>
</header>

<router-outlet></router-outlet>

<footer class="site-footer bg-dark">
  <div class="container">
    <div class="row g-4">
      <div class="col-lg-3">
        <div class="footer-logo pb-4">
          <a href="#">
            <img src="/assets/images/logo.png" alt="Site Logo" class="img-fluid mr-2 d-inline-block">
            <span class="mr-2">Raid Organizer</span>
          </a>
        </div>
        <div class="social-links">
          <div class="sl-title text-white fw-bolder pb-2">Follow</div>
          <ul class="list-group list-group-horizontal gap-3">
            <li class="list-group-item border-0 rounded-circle"><a href="http://discord.raidorganizer.org/" target="_blank" aria-label="Join our discord server"><i class="fa-brands fa-discord"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="footer-widget">
          <h3 class="widget-title">Legal</h3>
          <ul class="footer-menu navbar-nav d-xl-flex align-items-start">
            <li class="nav-item">
              <a routerLink="terms" (click)="scrollToTop()">Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a routerLink="privacy" (click)="scrollToTop()">Privacy Information</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="footer-widget">
          <h3 class="widget-title">Development</h3>
          <ul class="footer-menu navbar-nav d-xl-flex align-items-start">
            <li class="nav-item">
              <a routerLink="about" (click)="scrollToTop()">About Us</a>
            </li>
            <li class="nav-item">
              <a href="https://docs.raidorganizer.org/patch-notes/" target="_blank">Patch Notes</a>
            </li>
          </ul>
        </div>

      </div>
      <div class="col-lg-3">
        <div class="footer-widget">
          <h3 class="widget-title">Links</h3>
          <ul class="footer-menu navbar-nav d-xl-flex align-items-start">
            <li class="nav-item">
              <a href="http://discord.raidorganizer.org" target="_blank">Official Discord</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-sec">
      <div class="row copyrights-info">
        <div class="col-lg-6 copyright-col text-lg-start text-center">
          <!-- Footer Copyrights -->
          <div class="copyrights-content">
            ©2020-{{currentYearLong()}} Copyright: <a href="/#" class="ms-2">raidorganizer.org</a>
          </div>
          <!-- Footer Copyrights End -->
        </div>
        <div class="col-lg-6 mt-lg-0 mt-2">
          <div
            class="d-flex align-items-center justify-lg-content-end justify-content-center flex-sm-row flex-column copyrights-content">
            <p class="ms-3 ps-3 border-sm-start border-0" style="border-color: rgb(177, 177, 177) !important;">Owned
              by <i>Snawe#8570</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
