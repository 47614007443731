import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    const weeks = Math.floor(value / (7 * 24 * 60 * 60));
    value -= weeks * 7 * 24 * 60 * 60;

    const days = Math.floor(value / (24 * 60 * 60));
    value -= days * 24 * 60 * 60;

    const hours = Math.floor(value / (60 * 60));
    value -= hours * 60 * 60;

    const minutes = Math.floor(value / 60);

    const result = [];
    if (weeks > 0) {
      result.push(`${weeks}w`);
    }
    if (days > 0) {
      result.push(`${days}d`);
    }
    if (hours > 0) {
      result.push(`${hours}h`);
    }
    if (minutes > 0) {
      result.push(`${minutes}m`);
    }
    if (result.length == 0){
      result.push(`0m`)
    }

    return result.join(' ');
  }

}
