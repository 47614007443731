import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { shareReplay, switchMap } from 'rxjs/operators';
import { CustomEmoji, CustomSignupTemplate } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';

@Component({
  selector: 'app-signupTemplate-card',
  templateUrl: './signupTemplate-card.component.html',
  styleUrls: ['./signupTemplate-card.component.css']
})
export class SignupTemplateCardComponent implements OnInit {
  guildId: string;
  signupTemplateId: string;
  @Input() customEmojis$: Observable<CustomEmoji[]>;
  @Input() signupTemplateInfo$: Observable<CustomSignupTemplate>;
  combinedData$: Observable<{ signupTemplateInfo: CustomSignupTemplate, customEmojis: CustomEmoji[] }>;

  constructor(
    private route: ActivatedRoute,
    private botService: BotResourceService,
  ) {}

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.customEmojis$ = this.botService.getGuildEmoji(this.guildId).pipe(
      shareReplay(1)
    );
    this.customEmojis$.subscribe(customEmojis => {
      // iterate over custom emojis and make the string `name` a list with a single entry
      customEmojis.forEach(customEmoji => {
        customEmoji.shortNames = [customEmoji.name, customEmoji.full_id];
      });
    });
    // Angular reuses the component when navigating between routes within the same component.
    // This means that the component is not destroyed and recreated, so ngOnInit is not called again.
    // We therefore need to subscribe to the paramMap observable and update the component when the route changes.
    this.route.paramMap
    .pipe(
      switchMap((params: ParamMap) => {
        this.signupTemplateId = params.get('signupTemplateId');
        if (this.signupTemplateId === null) {
        // Use default values when the signupTemplateId is 'new'
          this.signupTemplateInfo$ = of({
            // Default values for a new signup template
            name: '',
            color: '#000000', // Example default color
            icon: '',
            emoji_behavior: null,
            emoji_title: false,
            emojis: {},
            limits: {}
          });
        } else {
          // Fetch data from the API
          this.signupTemplateInfo$ = this.botService.getSignupTemplate(this.guildId, this.signupTemplateId).pipe(
            shareReplay(1)
          );
        }
        this.combinedData$ = forkJoin({
          signupTemplateInfo: this.signupTemplateInfo$,
          customEmojis: this.customEmojis$
        });
        return of(null)
      }),
    ).subscribe(
      //print the template
      () => {},
    );
  }
}
