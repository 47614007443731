<div class="pt-3 ml-3 text-center">
  <h1>Signup Templates</h1>
  <p><code>Signup Templates</code> controll how sign ups to events are processed.
    They define the emojis, the sign up behavior and sign up limits. Read more about
    them <a href="https://docs.raidorganizer.org/signup-templates/" target="_blank">here</a>.</p>
</div>

<div class="row" *ngIf="combinedData$ | async as combinedData">
  <div class="col-md-12 col-lg-7">
    <h3 class="text-center">Settings</h3>
    <app-signup-template-builder [serverCustomEmojis]="combinedData.customEmojis" [signupTemplate]="combinedData.signupTemplateInfo"></app-signup-template-builder> <!-- EmbedBuilderComponent used here -->
  </div>

  <mat-divider [vertical]="true"></mat-divider>

  <div class="col-md-12 col-lg-4 mt-md-0 mt-3">
    <h3>Preview</h3>
    <app-embed-preview [embed]="currentEmbed"></app-embed-preview>
  </div>
</div>


<!--
  TODO's:
  BUGS:
  MVP:
  Other:
    * Loading till template is loaded
 -->
