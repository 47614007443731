import { Injectable } from '@angular/core';
import { CustomEmoji } from '../models/bot/guild';

@Injectable({
  providedIn: 'root',
})
export class EmojiParserService {
  constructor() {}

  parseEmojiForReaction(emoji: string, customEmojis: CustomEmoji[]) {
    // This function returns the DiscordEmoji instance for the reaction
    const customEmoji = customEmojis.find((customEmoji) =>
      emoji.includes(customEmoji.id)
    );
    if (customEmoji) {
      return { url: customEmoji.imageUrl, name: customEmoji.name, count: 1 };
    } else if (
      emoji.includes(':') &&
      emoji.includes('<') &&
      emoji.includes('>')
    ) {
      const emojiParts = emoji.match(/<a?:(\w+):(\d+)>/);
      if (emojiParts && emojiParts.length === 3) {
        const isAnimated = emoji.startsWith('<a:');
        const emojiId = emojiParts[2];
        const imageUrl = `https://cdn.discordapp.com/emojis/${emojiId}${
          isAnimated ? '.gif' : '.png'
        }`;
        return { url: imageUrl, name: emojiParts[1], count: 1 };
      }
    }
    return { url: emoji, name: emoji, count: 1 };
  }

  isCustomEmoji(emoji: string) {
    return emoji.includes(':') && emoji.includes('<') && emoji.includes('>')
  }

  parseCustomEmojiForEmbed(emoji: string, customEmojis: CustomEmoji[]) {
    // This function returns an image tag with the emoji to be displayed in the embed preview
    const customEmoji = customEmojis.find(customEmoji => emoji.includes(customEmoji.id));
    if (customEmoji) {
      return `<img class="custom-button-image" src="${customEmoji.imageUrl}" alt="${customEmoji.name}"></img>`
    } else {
      // Emoji does not belong to this server. We try best effort to display the emoji anyway.
      // Emoji comes in format of `<a:name:id>` if anmiated, else `<name:id>`
      // We need to get the id and append it to the url `https://cdn.discordapp.com/emojis/<id>`
      // We also need to check if the emoji is animated. If it is, we append `.gif` to the url, else `.png`
      const emojiParts = emoji.match(/<a?:(\w+):(\d+)>/);
      if (emojiParts && emojiParts.length === 3) {
        const isAnimated = emoji.startsWith('<a:');
        const emojiId = emojiParts[2];
        const imageUrl = `https://cdn.discordapp.com/emojis/${emojiId}${isAnimated ? '.gif' : '.png'}`;
        return `<img class="custom-button-image" src="${imageUrl}" alt="${emojiParts[1]}"></img>`;
      }
    }
  }
}
