import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BotGuild } from 'src/app/models/bot/guild';
import { PermissionValue } from 'src/app/models/discord-api/permission';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { PermissionsService } from 'src/app/services/permissions.service';


@Component({
  selector: 'app-guild-selection',
  templateUrl: './guild-selection.component.html',
  styleUrls: ['./guild-selection.component.css']
})
export class GuildSelectionComponent implements OnInit {
  allGuilds$!: Observable<BotGuild[]>;
  filteredGuilds$!: Observable<BotGuild[]>;
  interactableGuilds$!: Observable<BotGuild[]>;
  invitableGuilds$!: Observable<BotGuild[]>;
  notJoinableGuilds$!: Observable<BotGuild[]>;

  filterText = '';

  constructor(
    private botResourceService: BotResourceService,
    private permissionsService: PermissionsService,
    private oauthService: OAuthService,
    ) { }

  ngOnInit() {
    this.getAllGuilds();
    this.getFilteredGuilds();
    this.getInteractableGuilds();
    this.getInvitableGuilds();
    this.getNotJoinableGuilds();
    // this.getRaidOrganizerGuild();
  }

  getRaidOrganizerGuild() {
    const raidOrganizer = this.allGuilds$.pipe(
      map((guilds: BotGuild[]) => guilds.find(
        (guild) => guild.id === '658007285205041153'
      )),
      shareReplay(1) // Share the emitted value with multiple subscribers
    );
  }

  filterGuilds() {
    this.getFilteredGuilds();
    this.getInteractableGuilds();
    this.getInvitableGuilds();
    this.getNotJoinableGuilds();
  }

  getAllGuilds() {
    this.allGuilds$ = this.botResourceService.getGuildsList().pipe(
      map((guildStati: BotGuild[]) => guildStati.sort(
        (left: BotGuild, right: BotGuild) => left.name.localeCompare(right.name),
      ).filter(guild => guild.name.toLowerCase().includes(this.filterText.toLowerCase()))),
      shareReplay(1) // Share the emitted values with multiple subscribers
    );
  }

  getFilteredGuilds() {
    this.filteredGuilds$ = this.allGuilds$.pipe(
      map((guildStati: BotGuild[]) => guildStati.filter(
        (guildStatus) => guildStatus.id !== '658007285205041153',
      ).filter(guild => guild.name.toLowerCase().includes(this.filterText.toLowerCase())
      ).sort((left: BotGuild, right: BotGuild) => left.name.localeCompare(right.name),
      )),
      shareReplay(1) // Share the emitted values with multiple subscribers
    );
  }

  getInteractableGuilds() {
    this.interactableGuilds$ = this.filteredGuilds$.pipe(
      map(guilds => Object.values(guilds).filter(guild => guild.joined)),
      shareReplay(1) // Share the emitted values with multiple subscribers
    );
  }

  getInvitableGuilds() {
    this.invitableGuilds$ = this.filteredGuilds$.pipe(
      map(guilds => Object.values(guilds).filter(guild => !guild.joined).filter(
        (guildStatus) => guildStatus.owner || this.permissionsService.hasPermission(guildStatus.permissions, PermissionValue.ADMINISTRATOR),
      )),
      shareReplay(1) // Share the emitted values with multiple subscribers
    );
  }

  getNotJoinableGuilds() {
    this.notJoinableGuilds$ = this.filteredGuilds$.pipe(
      map(guilds => Object.values(guilds)
      .filter(
        (guildStatus) => !guildStatus.owner,
      ).filter(
        (guildStatus) => !guildStatus.joined,
      ).filter(
        (guildStatus) => !this.permissionsService.hasPermission(guildStatus.permissions, PermissionValue.ADMINISTRATOR),
      )),
      shareReplay(1) // Share the emitted values with multiple subscribers
    );
  }

  botAccess(id: string): void {
    this.oauthService.scope = 'applications.commands bot';
    this.oauthService.initCodeFlow(null, {
      permissions: this.permissionsService.getBotAccessPermissions(),
      guild_id: id,
    });
  }
}
