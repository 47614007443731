import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BotGuild, ChannelConfig, CustomEmoji, CustomSignupTemplate, GearInfo, GuildOverview, ServerInfo, UserConfig } from '../models/bot/guild';
import { StatisticsData } from '../models/bot/statistics';
import { Me } from '../models/bot/me';
import { catchError, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';
import { sign } from 'crypto';

@Injectable({
  providedIn: 'root'
})
export class BotResourceService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  getPersonalData(): Observable<Me> {
    return this.http.get<Me>('api/me');
  }

  getGuildsList(): Observable<BotGuild[]> {
    return this.http.get<BotGuild[]>('api/internal/guilds');
  }

  getGuild(guildId: string): Observable<BotGuild> {
    return this.http.get<BotGuild>('api/internal/guilds', { params: { guild_id: guildId }}).pipe(
      catchError((error) => {
        const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        return throwError(error); // Re-throw the error for further handling if needed
      })
    );
  }

  postCommand(guildId: string, command: string, value: string, channelId?: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>('api/internal/guild',
      { guild_id: guildId, command: command, value: value, channel_id: channelId });
  }

  getStatisticsData(): Observable<StatisticsData> {
    return this.http.get<StatisticsData>('api/internal/statistics');
  }

  exchangeGear(guildId: string, exchangeService: string, reminder: number): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>('api/internal/guild',
    { guild_id: guildId, command: "gears guild consume", value: exchangeService, reminder: reminder });
  }

  getGuildOverview(guildId: string): Observable<any> {
    return this.http.get<GuildOverview>('api/internal/guild/overview', { params: { guild_id: guildId }}).pipe(
      catchError((error) => {
        const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        return throwError(error); // Re-throw the error for further handling if needed
      })
    );
  }

  getGuildServer(guildId: string): Observable<any> {
    return this.http.get<ServerInfo>('api/internal/guild/server', { params: { guild_id: guildId }}).pipe(
      catchError((error) => {
        const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        return throwError(error); // Re-throw the error for further handling if needed
      })
    );
  }

  getGuildChannel(guildId: string, channelId: string): Observable<any> {
    return this.http.get<ChannelConfig>('api/internal/guild/channel', { params: { guild_id: guildId, channel_id: channelId }}).pipe(
      catchError((error) => {
        const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        return throwError(error); // Re-throw the error for further handling if needed
      })
    );
  }

  getSignupTemplate(guildId: string, signupTemplateId: string): Observable<any> {
    return this.http
      .get<CustomSignupTemplate>(`api/internal/guild/${guildId}/signupTemplate/${signupTemplateId}`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getEventCreateInfos(guildId: string): Observable<any> {
    return this.http
      .get<any>(`api/internal/guild/${guildId}/eventCreateInfos`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  postSignupTemplate(guildId: string, signupTemplateId: string, template: string, idChange: boolean): any  {
    const url = `api/internal/guild/${guildId}/signupTemplate/${signupTemplateId}`;

    // Send the PATCH request
    return this.http.patch<CustomSignupTemplate>(url, { template: template, id_change: idChange }).pipe(
      tap({
        next: () => {
          // Success handler
          this.toastr.success('Template updated successfully!', 'Success', { timeOut: 3000 });
        },
        error: (error) => {
          // Error handler within the tap is only for logging; actual error handling is in catchError
        }
      }),
      catchError((error) => {
        // Extract error message or default to 'Unknown error.'
        const errorMessage = error?.error?.error || 'Unknown error.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 3000 });
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  createSignupTemplate(guildId: string, template: string): Observable<any> {
    return this.http
      .post<CustomSignupTemplate>(`api/internal/guild/${guildId}/signupTemplate`, { template: template, id_change: true }, { observe: 'response' })
      .pipe(
        tap({
          next: () => {
            // Success handler
            this.toastr.success('Template successfully created!', 'Success', { timeOut: 3000 });
          },
          error: (error) => {
            // Error handler within the tap is only for logging; actual error handling is in catchError
          }
        }),
        catchError((error) => {
          const errorMessage = error?.error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  deleteSignupTemplate(guildId: string, signupTemplateId: string): Observable<any> {
    return this.http
      .delete<CustomSignupTemplate>(`api/internal/guild/${guildId}/signupTemplate/${signupTemplateId}`, { observe: 'response' })
      .pipe(
        tap({
          next: () => {
            // Success handler
            this.toastr.success('Template successfully deleted!', 'Success', { timeOut: 3000 });
          },
          error: (error) => {
            // Error handler within the tap is only for logging; actual error handling is in catchError
          }
        }),
        catchError((error) => {
          const errorMessage = error?.error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  createEvent(guildId: string, event: any): Observable<any> {
    return this.http
      .post<CustomEvent>(`api/internal/guild/${guildId}/event`, event, { observe: 'response' })
      .pipe(
        tap({
          next: () => {
            // Success handler
            this.toastr.success('Event successfully created!', 'Success', { timeOut: 3000 });
          },
          error: (error) => {
            // Error handler within the tap is only for logging; actual error handling is in catchError
          }
        }),
        catchError((error) => {
          const errorMessage = error?.error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  getGuildEmoji(guildId: string): Observable<any> {
    return this.http
      .get<CustomEmoji>(`api/internal/guild/${guildId}/emojis`)
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error?.error || error?.error || 'Unknown error.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }

  gearExchange(guilId: string, view_value: string, value: string, reminder: number, actionCallback?: () => void): void {
    // console.log('gearExchange', guilId, value, reminder)
    this.exchangeGear(guilId, value, reminder).pipe(
      take(1),
    ).subscribe(
      () => {
        this.toastr.success('Saved successfully', view_value, { timeOut: 2000 });
        if (actionCallback) {
          actionCallback();
        }
      },
      (error) => {
        const errorMessage = error?.error?.error || 'Unknown error. Could not save.';
        this.toastr.error(errorMessage, view_value, { timeOut: 2000 });
      }
    );
  }

  sendCommand(guilId: string, command: string, value: string, channelId?: string, actionCallback?: () => void): void {
    // console.log('sendCommand', guilId, command, value, channelId)
    this.postCommand(guilId, command, value, channelId).pipe(
      take(1),
    ).subscribe(
      () => {
        this.toastr.success('Saved successfully', command, { timeOut: 2000 });
        if (actionCallback) {
          actionCallback();
        }
      },
      (error) => {
        const errorMessage = error?.error?.error || 'Unknown error. Could not save.';
        this.toastr.error(errorMessage, command, { timeOut: 2000 });
      }
    );
  }

  getGuildUser(guildId: string) {
    return this.http.get<UserConfig>('api/internal/guild/user', { params: { guild_id: guildId }}).pipe(
      catchError((error) => {
        const errorMessage = error?.error?.error || error?.error || 'Unknown error. Could not fetch user info.';
        this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
        return throwError(error); // Re-throw the error for further handling if needed
      })
    );
  }

  getGuildGear(guildId: string): Observable<GearInfo> {
    return this.http.get<GearInfo>('api/internal/guild/gears', { params: { guild_id: guildId } })
      .pipe(
        catchError((error) => {
          const errorMessage = error?.error?.error || error?.error || 'Unknown error. Could not fetch gear info.';
          this.toastr.error(errorMessage, 'Error', { timeOut: 2000 });
          return throwError(error); // Re-throw the error for further handling if needed
        })
      );
  }
}
