import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { validColorValidator } from 'ngx-colors';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { BotGuild, ChannelConfig } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';
import { DurationPipe } from 'src/app/pipes/duration.pipe';

@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.css']
})
export class ChannelCardComponent implements OnInit {
  faIcons = {
    trash: faTrash,
  };

  guildId: string;
  channelId: string;
  guild: BotGuild;

  resetBehavior: string; // currently selected reset behavior
  countBehavior: string; // currently selected count behavior
  resetBehaviorCurrent: string; // currently saved reset behavior in backend. Used to determine if the reset behavior has changed.
  countBehaviorCurrent: string; // currently saved count behavior in backend. Used to determine if the count behavior has changed.
  // Export File Config
  exportFile: string;
  exportFileCurrent: string;
  exportSignupState: string;
  exportSignupStateCurrent: string;

  userDeleteBehavior: string;
  organizerDeleteBehavior: string;
  userDeleteBehaviorCurrent: string;
  organizerDeleteBehaviorCurrent: string;

  threadCreateStatus: boolean;
  threadAutoDelStatus: boolean;

  public eventCloseColorForm: FormGroup = new FormGroup(
    {
      closeColorInputCtrl: new FormControl("#000000", validColorValidator()),
      pickerCtrl: new FormControl("#000000"),
    },
    { updateOn: "change" }
  );

  @Input() channelInfo$: Observable<ChannelConfig>;

  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
  ) { }

  ngOnInit(): void {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });

    // Angular reuses the component when navigating between routes within the same component.
    // This means that the component is not destroyed and recreated, so ngOnInit is not called again.
    // We therefore need to subscribe to the paramMap observable and update the component when the route changes.
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.channelId = params.get('channelId');
          this.channelInfo$ = this.botService.getGuildChannel(this.guildId, this.channelId).pipe(
            shareReplay(1)
          );
          this.channelInfo$.subscribe(channel => {
            if (channel && channel.sign_up_numbers_behavior) {
              // console.log(channel.sign_up_numbers_behavior)
              this.countBehavior = Math.floor(channel.sign_up_numbers_behavior / 10).toString();
              this.resetBehavior = (channel.sign_up_numbers_behavior % 10).toString();
              this.countBehaviorCurrent = this.countBehavior;
              this.resetBehaviorCurrent = this.resetBehavior;
            }
            if (channel && channel.clean_mode) {
              this.organizerDeleteBehavior = Math.floor(Number(channel.clean_mode) / 10).toString();
              this.userDeleteBehavior = (Number(channel.clean_mode) % 10).toString();
              this.organizerDeleteBehaviorCurrent = this.organizerDeleteBehavior;
              this.userDeleteBehaviorCurrent = this.userDeleteBehavior;
            }
            if (channel) {
              this.eventCloseColorForm.controls["closeColorInputCtrl"].setValue("#" + channel.closed_color, { emitEvent: false, })
              this.eventCloseColorForm.controls["pickerCtrl"].setValue("#" + channel.closed_color, { emitEvent: false, })
              if (!(this.guild.owner || channel.has_organizer_role)) {
                this.eventCloseColorForm.controls["closeColorInputCtrl"].disable()
                this.eventCloseColorForm.controls["pickerCtrl"].disable()
              }
              this.threadCreateStatus = channel.use_threads
              this.threadAutoDelStatus = channel.thread_auto_del
              // Export File Settings
              this.exportFile = channel.export_file.toString()
              this.exportFileCurrent = channel.export_file.toString()
              this.exportSignupState = channel.export_signup_state.toString()
              this.exportSignupStateCurrent = channel.export_signup_state.toString()
            }
          });
          return of(null)
        }),
      )
      .subscribe(
        () => {
          // console.log(this.channelId)
          // console.log(this.guildId)
          // console.log(this.channelInfo$.subscribe(channel => console.log(channel)))
          // console.log(this.guild)
        }
      );

      this.eventCloseColorForm.controls["closeColorInputCtrl"].valueChanges.subscribe((color) => {
        // Called on manual value change
        // Call picker because picker updates the view field
        if (this.eventCloseColorForm.controls["pickerCtrl"].valid) {
          this.eventCloseColorForm.controls["pickerCtrl"].setValue(color, {
            emitEvent: false,
          });
        }
      });
      this.eventCloseColorForm.controls["pickerCtrl"].valueChanges.subscribe((color) => {
        // Called on color select via picker
        // Update view value
        this.eventCloseColorForm.controls["closeColorInputCtrl"].setValue(color, {
          emitEvent: false,
        });
        if (this.eventCloseColorForm.controls["closeColorInputCtrl"].valid) {
          this.botService.sendCommand(this.guildId, 'event_close color set', this.eventCloseColorForm.controls["closeColorInputCtrl"].value, this.channelId);
        }
      });
  }

  setOrganizerRole(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'channel organizer set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  setExportChannel(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'export_event channel set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  setEventCloseTime(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'event_close time set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  setEventCloseBehavior(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'event_close behavior set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  setUserRole(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'channel user set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  toggleUserConfirmationMessage(): void {
    this.botService.sendCommand(this.guildId, 'toggle user_confirmation_message', '', this.channelId);
  }

  toggleOrganizerUnconfirmedMessages(): void {
    this.botService.sendCommand(this.guildId, 'toggle organizer_unconfirmed_messages', '', this.channelId);
  }

  toggleThreads(): void {
    this.botService.sendCommand(this.guildId, this.threadCreateStatus ? 'thread disable': 'thread enable', '1', this.channelId);
    this.threadCreateStatus = !this.threadCreateStatus
  }

  toggleThreadAutodelete(): void {
    this.botService.sendCommand(this.guildId, 'thread autodelete', this.threadAutoDelStatus ? 'off' : 'on', this.channelId);
    this.threadAutoDelStatus = !this.threadAutoDelStatus
  }

  toggleExportEventOnClose(): void {
    this.botService.sendCommand(this.guildId, 'export_event toggle', 'export_event toggle', this.channelId);
  }

  toggleUserChoices(): void {
    this.botService.sendCommand(this.guildId, 'toggle show_user_choices', '', this.channelId);
  }

  toggleFooterTime(): void {
    this.botService.sendCommand(this.guildId, 'toggle footer_time', '', this.channelId);
  }

  toggleEventlClosure(): void {
    this.botService.sendCommand(this.guildId, 'event_close toggle', '', this.channelId);
  }

  toggleHereNotification(): void {
    this.botService.sendCommand(this.guildId, 'toggle here_notification', '', this.channelId);
  }

  toggleScheduledEvents(): void {
    this.botService.sendCommand(this.guildId, 'toggle discord_events', '', this.channelId);
  }

  toggleEventCreateOverlapNote(): void {
    this.botService.sendCommand(this.guildId, 'toggle event_create_overlap_note', '', this.channelId);
  }

  toggleShowUserChoices(): void {
    this.botService.sendCommand(this.guildId, 'toggle show_user_choices', '', this.channelId);
  }

  setSignupNumberDisplay(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'show_sign_up_numbers', eventTarget.toString(), this.channelId);
  }

  setConfirmBehavior(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'confirm_behavior set', eventTarget.toString(), this.channelId);
  }

  setDisplayNames(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'display_names set', eventTarget.toString(), this.channelId);
  }

  setSignupNumberBehavior(){
    const sign_up_behavior = (this.countBehavior + this.resetBehavior).toString();
    this.botService.sendCommand(this.guildId, 'sign_up_number_behavior set', sign_up_behavior, this.channelId);
    this.resetBehaviorCurrent = this.resetBehavior;
    this.countBehaviorCurrent = this.countBehavior;
  }

  setExportFileConfig(){
    const exportConfig = (this.exportFile + this.exportSignupState).toString();
    this.botService.sendCommand(this.guildId, 'export_event add_file', exportConfig, this.channelId);
    this.exportFileCurrent = this.exportFile;
    this.exportSignupStateCurrent = this.exportSignupState;
  }

  setCleanMode() {
    const clean_mode = (this.organizerDeleteBehavior + this.userDeleteBehavior).toString();
    this.botService.sendCommand(this.guildId, 'clean_mode set', clean_mode, this.channelId);
    this.organizerDeleteBehaviorCurrent = this.organizerDeleteBehavior;
    this.userDeleteBehaviorCurrent = this.userDeleteBehavior;
  }

  setEventDeletionTime(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'event_deletion_time set', (eventTarget as HTMLInputElement).value, this.channelId);
  }

  onEventDeletionTimeInput(input: HTMLInputElement) {
    const newValue = +input.value; // Convert the input value to a number
    const max = this.guild.has_premium ? 60 : 14; // Maximum value

    // Subscribe to the channelInfo Observable to get the current value
    this.channelInfo$.pipe(take(1)).subscribe((channelInfo) => {
      // Check if the new value exceeds the maximum
      if (newValue > max) {
        // Reset the value to the previous value
        input.value = (channelInfo.event_deletion_time).toString();
      } else {
        // Update the channelInfo property with the new value
        channelInfo.event_deletion_time = newValue;
      }
    });
  }

  setDefaultTemplate(eventTarget: EventTarget): void {
    this.botService.sendCommand(this.guildId, 'default_signup_template set', (eventTarget as HTMLInputElement).value.toString(), this.channelId);
  }

  removeRepeatingEvent(eventTarget: string): void {
    this.botService.sendCommand(this.guildId, 'repeating_event remove', eventTarget, this.channelId, () => {
      this.channelInfo$.subscribe(channelInfo => {
        channelInfo.repeating_events = channelInfo.repeating_events.filter(event => event.id !== eventTarget);
      })
    });
  }
}
