<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Documentation</h1>
    <p class="bd-lead">Learn about available commands here</p>
  </div>
</header>

<section class="p-5 guild-bg-dark">
  <div>
    Help signature:
    <ul>
      <li><code>< argument ></code> This means the argument is required.</li>
      <li><code>[ argument ]</code> This means the argument is optional.</li>
      <li><code>[ argument... ]</code> This means you can have multiple arguments.</li>
    </ul>
  </div>

  <div class="my-3 d-flex flex-column flex-md-row justify-content-md-between">
    <button class="btn btn-primary mb-3 mb-md-0" (click)="toggleValue = !toggleValue">
      Commands by {{ toggleValue ? 'role' : 'usage' }}
    </button>

    <input placeholder="Filter command" (input)="updateFilterText($event)" />
  </div>

  <div *ngIf="toggleValue" class="accordion" id="accordionPermission">
    <div class="card" *ngFor="let entry of permissionOptions; let i = index, let first = first">
      <div class="card-header" [attr.id]="'heading' + i">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" [attr.aria-expanded]="first"
                  [attr.data-target]="'#collapse' + i"  [attr.aria-controls]="'collapse' + i" (click)="scrollToTop()">
            {{ entry.text }}
          </button>
        </h2>
      </div>

      <div [attr.id]="'collapse' + i" class="collapse show" [ngClass]="{ show: first }"
           [attr.aria-labelledby]="'heading' + i" data-parent="#accordionPermission">
        <div class="card-body">
          <app-commands [commands]="entry.data"></app-commands>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!toggleValue" class="accordion" id="accordionUsage">
    <div class="card" *ngFor="let entry of usageOptions; let i = index, let first = first">
      <div class="card-header" [attr.id]="'heading' + i">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" [attr.aria-expanded]="first"
                  [attr.data-target]="'#collapse' + i"  [attr.aria-controls]="'collapse' + i" (click)="scrollToTop()">
            {{ entry.text }}
          </button>
        </h2>
      </div>

      <div [attr.id]="'collapse' + i" class="collapse" [ngClass]="{ show: first }"
           [attr.aria-labelledby]="'heading' + i" data-parent="#accordionUsage">
        <div class="card-body">
          <app-commands [commands]="entry.data"></app-commands>
        </div>
      </div>
    </div>
  </div>
</section>
