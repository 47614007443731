import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Command } from '../../../models/internal/command';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css'],

})
export class CommandsComponent {

  @Input()
  commands: Command[];

  constructor() { }

}
