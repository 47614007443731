import { Component, OnInit } from '@angular/core';
import commandJson from '../../constants/commands.json';
import { UsageLevel, PermissionLevel, Command } from '../../models/internal/command';

interface CommandOptions {
  text: string;
  data: Command[];
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent implements OnInit {
  toggleValue = false;

  commands: Command[];
  permissionOptions: CommandOptions[];
  usageOptions: CommandOptions[];

  ngOnInit(): void {
    this.commands = commandJson as Command[];
    this.setData();
  }

  setData(filter: string = ''): void {
    this.permissionOptions = [
      { text: 'Owner/Moderator', data: this.getCommandsByPermission(PermissionLevel.OWNER, filter) },
      { text: 'Organizer', data: this.getCommandsByPermission(PermissionLevel.ORGANIZER, filter) },
      { text: 'User', data: this.getCommandsByPermission(PermissionLevel.USER, filter) },
    ];

    this.usageOptions = [
      { text: 'Manage Server Wide Settings', data: this.getCommandsByUsage(UsageLevel.SERVER, filter) },
      { text: 'Manage Channel Wide Settings', data: this.getCommandsByUsage(UsageLevel.CHANNEL, filter) },
      { text: 'Manage User Settings', data: this.getCommandsByUsage(UsageLevel.USER, filter) },
      { text: 'Event Commands', data: this.getCommandsByUsage(UsageLevel.EVENTS, filter) },
      { text: 'Repeating Events Commands', data: this.getCommandsByUsage(UsageLevel.REPEATING_EVENTS, filter) },
      { text: 'Template Commands', data: this.getCommandsByUsage(UsageLevel.TEMPLATE, filter) },
      { text: 'Gear Commands', data: this.getCommandsByUsage(UsageLevel.GEARS, filter) },
      { text: 'Patreon Commands', data: this.getCommandsByUsage(UsageLevel.PATREON, filter) },
      { text: 'Other Stuff', data: this.getCommandsByUsage(UsageLevel.STUFF, filter) },
    ];
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  updateFilterText(event: Event): void {
    this.setData((event.target as HTMLInputElement).value.toLowerCase());
  }

  private getCommandsByPermission(permission: PermissionLevel, filter: string = ''): Command[] {
    return this.commands.filter(command => command.permission === permission)
      .filter(command => command.command.toLowerCase().includes(filter));
  }

  private getCommandsByUsage(usage: UsageLevel, filter: string = ''): Command[] {
    return this.commands.filter(command => command.usage_type === usage)
      .filter(command => command.command.toLowerCase().includes(filter));
  }

}
