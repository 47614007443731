import { Injectable } from '@angular/core';
import { UserInfo } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInfo: UserInfo;
constructor(

) { }

  setUserInfo(userInfo: UserInfo): void {
    this.userInfo = userInfo;
  }

  getUserInfo(): UserInfo {
    return this.userInfo;
  }

}
