import { Component, Input, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { endWith, scan, takeWhile } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

const INTERVAL = 20;

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['../style.css', '../home.component.css', ]

})
export class StatisticsComponent implements OnInit {

  @Input()
  speed: number;

  @Input()
  toCount: number;

  @Input()
  name: string;

  @Input()
  iconDefinition: IconDefinition;

  count$: Observable<number>;

  ngOnInit(): void {
    this.count$ = interval(INTERVAL).pipe(
      scan((acc, value) => acc + (this.toCount * INTERVAL / this.speed)),
      takeWhile(n => n < this.toCount),
      endWith(this.toCount),
    );
  }
}
