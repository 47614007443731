  <div class="container-fluid">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <div *ngIf="channelInfo$ | async as channelInfo">
        <h1>Channel: <i>{{ channelInfo.name }}</i></h1>
      </div>
    </div>
  </div>

  <div *ngIf="guild && channelInfo$ | async as channelInfo" class="pb-3 pt-2">
    <div *ngIf="!(guild.owner || channelInfo.has_organizer_role)"class="alert alert-warning h5">
      &#x26A0; Only users with the <a href="https://docs.raidorganizer.org/getting-started/terms#organizer-role" target="_blank">organizer role</a>,
      or higher permissions, can change those settings!
    </div>
  </div>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Role Settings</h4>
    <div class="row">
      <div class="col">
        <form class="organizerRoleSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="organizerRoleSelection">Organizer role</mat-label>
            <mat-select
                    [(ngModel)]="channelInfo.organizer_role"
                    name="organizerRole"
                    id="organizerRoleSelection"
                    [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                    (selectionChange)="setOrganizerRole($event)">
              <ng-container *ngFor="let role of channelInfo.roles">
                <mat-option [value]="role.id"
                        [disabled]="role.name === '@everyone'" [selected]="role.id === channelInfo.organizer_role">
                  {{ role.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <div class="mat-select-link-wrapper">
              <a href="https://docs.raidorganizer.org/getting-started/terms#organizer-role" target="_blank" class="mat-select-link">What is the Organizer Role?</a>
            </div>
          </mat-form-field>
        </form>
      </div>
      <div class="col">
        <form class="userRoleSelection">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="userRoleSelection">User role</mat-label>
            <mat-select
                    [(ngModel)]="channelInfo.user_role"
                    name="userRole"
                    id="userRoleSelection"
                    [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                    (selectionChange)="setUserRole($event)">
              <ng-container *ngFor="let role of channelInfo.roles">
                <mat-option [value]="role.id"
                        [selected]="role.id === channelInfo.user_role">
                  {{ role.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <div class="mat-select-link-wrapper">
              <a href="https://docs.raidorganizer.org/getting-started/terms#user-role" target="_blank" class="mat-select-link">What is the User Role?</a>
            </div>
          </mat-form-field>
        </form>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Signup Numbers</h4>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width-container" floatLabel="always">
          <mat-label>Sign Up Numbers</mat-label>
          <mat-select [(ngModel)]="channelInfo.show_sign_up_numbers" name="createScheduled" [disabled]="!(guild.owner || channelInfo.has_organizer_role)" (selectionChange)="setSignupNumberDisplay($event.value)" placeholder="Hide sign up numbers">
            <!-- FIXME: This is fucking buggy! On first call, where the backend sends "false", this shit is not selected!!! -->
            <mat-option [value]="0" [selected]="'0' === channelInfo.show_sign_up_numbers">
              Hide sign up numbers
            </mat-option>
            <mat-option [value]="1" [selected]="'1' === channelInfo.show_sign_up_numbers">
              Show sign up numbers on "role" emojis
            </mat-option>
            <mat-option [value]="2" [selected]="'2' === channelInfo.show_sign_up_numbers">
              Show sign up numbers on "status" emojis
            </mat-option>
            <mat-option [value]="3" [selected]="'3' === channelInfo.show_sign_up_numbers">
              Show sign up numbers on "role" and "status" emojis
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-label for="signupBehavior">Signup number behavior</mat-label>
    <div class="row pr-3">
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>Reset behavior</mat-label>
          <mat-select id="resetBehavior1" [(ngModel)]="resetBehavior" name="resetBehavior" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="0">Do not reset the sign up number on emoji change</mat-option>
            <mat-option value="1">Reset sign up number on "role" to "status" emoji change</mat-option>
            <mat-option value="2">Reset sign up number on every emoji change</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>Count behavior</mat-label>
          <mat-select id="countBehavior" [(ngModel)]="countBehavior" name="countBehavior" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="1">Count every sign up of a user. (User will have 2 numbers if signed up twice)</mat-option>
            <mat-option value="2">Only count a user once. (User will have only 1 number even if signed up twice)</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 pr-0">
        <button mat-button class="" mat-raised-button color="primary" [disabled]="!(resetBehavior && countBehavior) || (resetBehavior === resetBehaviorCurrent && countBehavior === countBehaviorCurrent)" (click)="setSignupNumberBehavior()">Confirm</button>
      </div>
    </div>
    <div class="toggleUserChoices">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.user_choices"
                        (change)="toggleUserChoices()">
        Show users preferred choice order next to their name
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle show_user_choices</small>
      </mat-slide-toggle>
    </div>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Closed Event Settings</h4>
    <div class="toggleEventlClosure">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.close_events"
                        (change)="toggleEventlClosure()">
        Automatically close events after the start time is reached
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/event_close toggle</small>
      </mat-slide-toggle>
    </div>

    <form [formGroup]="eventCloseColorForm">
      <mat-form-field class="full-width-container">
        <mat-label>Color of closed events</mat-label>
        <input matInput formControlName="closeColorInputCtrl">
        <ngx-colors
          class="mr-5"
          matSuffix
          ngx-colors-trigger
          formControlName="pickerCtrl"
          colorPickerControls="no-alpha"
        ></ngx-colors>
        <mat-error *ngIf="this.eventCloseColorForm.controls['closeColorInputCtrl'].hasError('invalidColor')">
          The color is invalid.
        </mat-error>
      </mat-form-field>
    </form>

    <mat-form-field class="full-width-container">
      <mat-label for="eventCloseTime">Close event X time before event start</mat-label>
      <input matInput class="form-control" id="eventCloseTime" placeholder="1w 2d 3h 10m"
        [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
        [value]="channelInfo.event_close_time | duration" (change)="setEventCloseTime($event.target)"
      />
      <mat-hint align="end">Combination of w(eeks), d(ays), h(ours), m(inutes). Example: 1d 5h</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width-container">
      <mat-label>Close Behavior</mat-label>
      <mat-select
        id="closeBehavior"
        [(ngModel)]="channelInfo.close_behavior"
        name="closeBehavior"
        [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
        (selectionChange)="setEventCloseBehavior($event)">
        <mat-option value="event start">Close relative to event start (default)</mat-option>
        <mat-option value="event end">Close relative to event end</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Export Event Settings</h4>
    <div class="toggleExportEventOnClose">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.export_event_on_close"
                        (change)="toggleExportEventOnClose()">
        Export event to export channel on close
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/export_event toggle</small>
      </mat-slide-toggle>
    </div>
    <form class="exportChannelSelection">
      <mat-form-field class="full-width-container" floatLabel="always">
        <mat-label for="exportChannelSelection">Export channel</mat-label>
        <mat-select
                [(ngModel)]="channelInfo.export_channel_id"
                name="exportChannel"
                id="exportChannelSelection"
                [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                (selectionChange)="setExportChannel($event)">
          <ng-container *ngFor="let channel of channelInfo.channels">
            <mat-option [value]="channel.id" [selected]="channel.id === channelInfo.export_channel_id">
              {{ channel.name }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-hint align="end">Channel to which events are exported</mat-hint>
      </mat-form-field>
    </form>
    <mat-label for="exportEventFile">Add event info file to exported events</mat-label>
    <div class="row pr-3">
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>Export file type</mat-label>
          <mat-select id="exportFile1" [(ngModel)]="exportFile" name="exportFile" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="0">Do not attach a file to the exported event</mat-option>
            <mat-option value="1">Attach a .csv file</mat-option>
            <mat-option value="2">Attach a .json file</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>User config</mat-label>
          <mat-select id="exportSignupState" [(ngModel)]="exportSignupState" name="exportSignupState" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="0">Don't export users</mat-option>
            <mat-option value="1">Only export signed up users</mat-option>
            <mat-option value="2">Only export signed off users</mat-option>
            <mat-option value="3">Export all users</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 pr-0">
        <button mat-button class="" mat-raised-button color="primary" [disabled]="(exportFile === exportFileCurrent && exportSignupState === exportSignupStateCurrent)" (click)="setExportFileConfig()">Confirm</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Confirm Settings</h4>
    <div class="toggleUserConfirmationMessage">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.msg_confirmed_usr"
                        (change)="toggleUserConfirmationMessage()">
        Users receive a confirmation message on confirmation
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle user_confirmation_message</small>
      </mat-slide-toggle>
    </div>
    <div class="declineMessageSwitch">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.get_msg_unconfirmed_usr"
                        (change)="toggleOrganizerUnconfirmedMessages()">
        Organizers receive a signed off message when a confirmed user signs off
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle organizer_unconfirmed_messages</small>
      </mat-slide-toggle>
    </div>
    <mat-form-field class="full-width-container" floatLabel="always">
      <mat-label>Confirm behavior</mat-label>
      <mat-select [(ngModel)]="channelInfo.confirmed_behavior" name="ConfirmBehavior" [disabled]="!(guild.owner || channelInfo.has_organizer_role)" (selectionChange)="setConfirmBehavior($event.value)">
        <mat-option [value]="1" [selected]="'1' === channelInfo.confirmed_behavior">
          Show confirm emoji next to user
        </mat-option>
        <mat-option [value]="2" [selected]="'2' === channelInfo.confirmed_behavior">
          Display confirmed users in separate field
        </mat-option>
        <mat-option [value]="3" [selected]="'3' === channelInfo.confirmed_behavior">
          Both
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Thread Settings</h4>
    <div class="threadToggle">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.use_threads"
                        (change)="toggleThreads()">
        Attach threads to events
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted" *ngIf="!threadStatus">/thread enable</small>
        <small class="form-text text-muted" *ngIf="threadStatus">/thread disable</small>
      </mat-slide-toggle>
    </div>
    <div class="threadAutodeleteToggle">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.thread_auto_del"
                        (change)="toggleThreadAutodelete()">
        Automatically delete threads when event is deleted
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/thread autodelete</small>
      </mat-slide-toggle>
    </div>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <h4>Miscellaneous</h4>

    <mat-form-field class="full-width-container" floatLabel="always">
      <mat-label>Display User Names</mat-label>
      <mat-select [(ngModel)]="channelInfo.display_names" name="DisplayNames" [disabled]="!(guild.owner || channelInfo.has_organizer_role)" (selectionChange)="setDisplayNames($event.value)">
        <mat-option [value]="1" [selected]="'1' === channelInfo.display_names">
          Server Nickname
        </mat-option>
        <mat-option [value]="2" [selected]="'2' === channelInfo.display_names">
          User Display Name
        </mat-option>
        <mat-option [value]="3" [selected]="'3' === channelInfo.display_names">
          Account Username
        </mat-option>
        <mat-option [value]="4" [selected]="'4' === channelInfo.display_names">
          @mention
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="toggleFooterTime">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.show_footer_time"
                        (change)="toggleFooterTime()">
        Show the event start time in the event footer as well
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle footer_time</small>
      </mat-slide-toggle>
    </div>

    <ng-container *ngIf="channelInfo.type !== 'event'">
      <div class="toggleAtHere">
        <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                          [checked]="channelInfo.at_here_notification"
                          (change)="toggleHereNotification()">
            Send @here notification on event creation
          <!-- TODO: Doc and cooldown -->
          <small class="form-text text-muted">/toggle here_notification</small>
        </mat-slide-toggle>
      </div>
    </ng-container>
    <div class="toggleScheduledEvents">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.create_scheduled"
                        (change)="toggleScheduledEvents()">
          Create Discord/Scheduled events on event creation
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle discord_events</small>
      </mat-slide-toggle>
    </div>
    <div class="toggleEventCreateOverlapNote">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role) || !guild.has_premium"
                        [checked]="channelInfo.overlap_note"
                        (change)="toggleEventCreateOverlapNote()">
          Get a notification message if you create an event that overlaps with another event
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle event_create_overlap_note</small>
      </mat-slide-toggle>
    </div>
    <div class="toggleShowUserChoices">
      <mat-slide-toggle [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
                        [checked]="channelInfo.user_choices"
                        (change)="toggleShowUserChoices()">
        On multiple sign ups, show preferred / other choices next to user
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle show_user_choices</small>
      </mat-slide-toggle>
    </div>

    <mat-form-field class="full-width-container">
      <mat-label for="eventDeletionInput">Event deletion time (in days)</mat-label>
      <input matInput class="form-control" id="eventDeletionInput" placeholder="Event deletion time"
        [disabled]="!(guild.owner || channelInfo.has_organizer_role)"
        [value]="+channelInfo.event_deletion_time" (input)="onEventDeletionTimeInput($event.target)" (change)="setEventDeletionTime($event.target)"
        min="0" [max]="(guild.has_premium) ? 60 : 14" />
      <mat-hint align="end">Max: {{(guild.has_premium) ? 60 : 14}}</mat-hint>
    </mat-form-field>

    <form class="organizerRoleSelection">
      <mat-form-field class="full-width-container" floatLabel="always">
        <mat-label for="templateSelection">Default Signup Template</mat-label>
        <mat-select [(ngModel)]="channelInfo.default_template" id="templateSelection" name="templateSelection" (selectionChange)="setDefaultTemplate($event)"
                [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
          <mat-optgroup label="Default templates">
            <mat-option *ngFor="let template of channelInfo.default_signup_templates; let i = index" [value]="i"
                    [selected]="i === +channelInfo.default_template">
              {{ template.name }}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Custom templates">
            <mat-option *ngFor="let template of channelInfo.custom_signup_templates; let i = index" [value]="i + 3"
                    [selected]="i === +channelInfo.default_template - 3">
              {{ template.name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>

    <mat-label for="CleanMode">Clean mode</mat-label>
    <div class="row pr-3">
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>Organizer behavior</mat-label>
          <mat-select id="organizerDeleteBehavior" [(ngModel)]="organizerDeleteBehavior" name="organizerDeleteBehavior" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="0">Delete all messages from organizers</mat-option>
            <mat-option value="1">Keep messages from organizers</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5 pr-0">
        <mat-form-field class="full-width-container">
          <mat-label>User behavior</mat-label>
          <mat-select id="userDeleteBehavior" [(ngModel)]="userDeleteBehavior" name="userDeleteBehavior" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
            <mat-option value="0">Delete all messages from users</mat-option>
            <mat-option value="1">Keep messages from users with the user role</mat-option>
            <mat-option value="2">Keep messages from all users. Also from ones without the user role</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 pr-0">
        <button mat-button class="" mat-raised-button color="primary" [disabled]="!(organizerDeleteBehavior && userDeleteBehavior) || (organizerDeleteBehavior === organizerDeleteBehaviorCurrent && userDeleteBehavior === userDeleteBehaviorCurrent)" (click)="setCleanMode()">Confirm</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="guild && channelInfo$ | async as channelInfo">
    <ng-container *ngIf="channelInfo.repeating_events && channelInfo.repeating_events.length > 0">
      <h4>Repeating Events List</h4>
      <table mat-table [dataSource]="channelInfo.repeating_events" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Event Title </th>
          <td mat-cell *matCellDef="let event"> {{event.name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let event">
            <button mat-icon-button (click)="removeRepeatingEvent(event.id)" [disabled]="!(guild.owner || channelInfo.has_organizer_role)">
              <fa-icon [icon]="faIcons.trash"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'actions'];"></tr>
      </table>
    </ng-container>
  </ng-container>
</div>
