<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">About us</h1>
    <p class="bd-lead">Learn about the origin of the raid organizer bot here</p>
  </div>
</header>

<section class="p-5 guild-bg-dark">
  <h2>The idea</h2>
  <p>
    At the beginning of 2019 <i>wolfsberger#1266</i> started with a first implementation of the now known bot <i>Raid Organizer</i>. The idea was to build a small bot so that our guild could create and plan events via Discord.
  </p>
  <p>
    Since the first (inofficial) version was only for our guild and <i>wolfsberger#1266</i> started his master thesis, I (<i>snawe#8570</i>) took over the project mid November 2019.
  <br>On December 23. 2019 the first beta version for a small amount of servers (~10) was released.
  <br>From there on the bot started to grow. Not only in active servers but also in functionality, options and customizability.
  <br>Even if <i>wolfsberger#1266</i> is rather inactive now, I (<i>snawe#8570</i>) am still working on updating
    the bot continuously and receive support from many sides in order to achieve this.
  </p>
  <p>
    After all <i>Raid Organizer</i> should be a bot <u>from</u> MMO players <u>for</u> MMO players!
  </p>
</section>
