import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { faCalendarCheck, faCheck, faList, faRepeat, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/operators';
import { BotGuild, GuildOverview } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.css']
})
export class OverviewCardComponent implements OnInit {
  faIcons = {
    users: faUsers,
    calendarCheck: faCalendarCheck,
    cross: faXmark,
    check: faCheck,
    list: faList,
    calendaPlus: faCalendarPlus,
    repeat: faRepeat,
  }

  @Input() overview$: Observable<GuildOverview>;
  guildId: string;
  guild: BotGuild;
  dummyArray = [1, 2, 3];

  constructor(
    private route: ActivatedRoute,
    private botService: BotResourceService,
    private guildService: GuildService,
  ) { }

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');

    this.overview$ = this.botService.getGuildOverview(this.guildId).pipe(
      shareReplay(1)
    );

    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });
  }
}
