<header class="d-flex flex-column flex-md-row align-items-md-center p-5 bg-light">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Getting started</h1>
    <p class="bd-lead">Learn about how to plan your first event here</p>
  </div>
</header>

<section class="p-5">
  <h1>!!! NOTE: This page is outdated !!!</h1>
  <p>Prefix commands (<code>!</code>) are no longer supported. Please use the new application commands (<code>/</code>).<br>
  Also the command syntax has changed. For a full list, checkout <a href="/migration">https://raidorganizer.org/migration</a>.<br>
  An update to this page will follow soonish. (I am always open for help xD)</p>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <img class="col-lg-6 order-lg-2 text-white showcase-img" src="/assets/setup_channel_cmd_light.webp" />
      <div class="col-lg-6 order-lg-1 my-auto showcase-text">
        <h2>Set up your server!</h2>
        <p>
          When you invite the bot to your server, it will automatically send a private message to the owner of the server.<br>
          If you don't get a private message from the bot (you aren't the owner, you disabled private messages, etc.), follow the steps below.<br><br>
          To set up your server you basically only need the <code>!setup_channel</code> command.
        </p>
        <ul>
          <li>Enter <code>!setup_channel</code> to let the bot guide you through the set up process.</li>
          <li>Enter <code>!setup_channel [channel type] [@organizer_role] [@user_role]</code> to do the set up in one command.</li>
        </ul>
        <b>INFO:</b><br>
        <ul>
          <li>If you are new to discord and need help with creation a role, please check out the <a href="https://support.discord.com/hc/en-us/articles/206029707-How-do-I-set-up-Permissions-" target="_blank">official discord article</a> about roles.</li>
          <li><b>Channel Type:</b> Allowed <code>channel types</code> are <i>event</i> and <i>lfg</i>.</li>
          <ul>
            <li><b>event:</b> Event channels are the classic way of organizing events or raids. Users with the <code>organizer_role</code> are able to create events and users with the <code>user_role</code> are able to sign up for those events.</li>
            <li><b>lfg:</b> LFG channels are perfect if you want to allow your members to create their own grind/lfg/events. In this mode every user with the <code>user_role</code> is able to create and manage their own events.</li>
          </ul>
          <li><b>organizer-Role:</b> Users that have the <code>organizer_role</code> are allowed to create, delete, edit, ... events. Basically they can manage the events inside an event channel.</li>
          <li><b>User-Role:</b> Users that have the <code>user_role</code> can join events over reactions. In an <i>event</i> channel they do not have permissions to create, remove, etc. events.</li>
          <li>To mention a role (<code>@</code>), the role needs to be mentionable. You can do this in your <i>Server Settings</i> =&gt; <i>Roles</i> =&gt; <i>Allow anyone to @mention this role</i></li>
        </ul>
        For more information checkout <a href="help">!help</a> or enter <code>!help [command]</code> in discord.
        <p></p>
      </div>
    </div>
    <div class="row no-gutters">
      <img class="col-lg-6 text-white showcase-img" src="/assets/create_event_cmd_light.webp" />
      <div class="col-lg-6 my-auto showcase-text">
        <h2>Create your first event!</h2>
        <p>
          To create an event you need the <code>!create</code> command
        </p>
        <ul>
          <li><code>!create [Title] [Date] [Optional Description]</code></li>
        </ul>
        <b>INFO:</b><br>
        <ul>
          <li><i>Title</i> and <i>date</i> are mandatory. The description is optional.</li>
          <li>For more advanced event creation use:</li>
          <ul>
            <li><code>!create</code> (without any parameters). The bot will send you a direct message and will guide you through the process of creating an event.</li>
            <li><code>!create --advanced</code> to create an advanced template via a single message.</li> 
          </ul>
        </ul>
        <p>Once you have created your first event users with the <code>user_role</code> are able to sign up to the event.</p>
        Users with the <code>organizer-role</code> have a lot of commands to manage their events. Here are a couple of them:
        <ul>
          <li>You can edit events with the <code>edit</code> command.</li>
          <li>You can delete events with the <code>remove</code> command.</li>
          <li>You can confirm users with the <code>confirm</code> command.</li>
          <li>And a lot more...</li>
        </ul>
        For more information checkout <a href="help">!help</a> or enter <code>!help [command]</code> in discord.
        <p></p>
      </div>
    </div>
    <div class="row no-gutters">
      <img class="col-lg-6 order-lg-2 text-white showcase-img" src="/assets/help_cmd_light.webp" />
      <div class="col-lg-6 order-lg-1 my-auto showcase-text">
        <h2>Need further help?</h2>
        <p class="lead mb-0">
        </p>
        <ul>
          <li>Use the <code>help</code> command. All commands are listed there according to their category.</li>
          <li>You can also join our <a href="https://discord.raidorganizer.org" target="_blank">discord server.</a></li>
          <li>Or you can visit the <a href="help.html">!help</a> page.</li>
        </ul>
        <p></p>
      </div>
    </div>
  </div>
</section>
