<header class="d-flex flex-column flex-md-row align-items-md-center p-5 bg-light">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Support us</h1>
    <p class="bd-lead">You like the idea? Keep it running and receive additional features</p>
  </div>
</header>

<section class="p-5">
  <div class="row">
    <div class="col-lg-4 mb-3">
      <div class="card">
        <div class="card-header">
          <h3>Vote:</h3>
          <p>Help us getting more visible with your vote!</p>
        </div>
        <div class="card-body">
          <a href="https://top.gg/bot/658698521570181140" target="_blank" class="btn btn-secondary bg-discord btn-block">
            <fa-icon [icon]="faIcons.discord"></fa-icon>
            <span class="ml-1">top.gg</span>
          </a>
          <a href="https://bots.ondiscord.xyz/bots/658698521570181140" target="_blank" class="btn btn-secondary bg-discord btn-block">
            <fa-icon [icon]="faIcons.discord"></fa-icon>
            <span class="ml-1">bots.ondiscord.xyz</span>
          </a>
          <a href="https://discord.bots.gg/bots/658698521570181140#raid-organizer" target="_blank" class="btn btn-secondary bg-discord btn-block">
            <fa-icon [icon]="faIcons.discord"></fa-icon>
            <span class="ml-1">discord.bots.gg</span>
          </a>
          <a href="https://discordbotlist.com/bots/658698521570181140" target="_blank" class="btn btn-secondary bg-discord btn-block">
            <fa-icon [icon]="faIcons.discord"></fa-icon>
            <span class="ml-1">discordbotlist.com</span>
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-3">
      <div class="card">
        <div class="card-header">
          <h3>Donate:</h3>
          <p>Help us running the servers and getting coffee!</p>
        </div>
        <div class="card-body">
          <a href="https://www.patreon.com/raid_organizer" target="_blank" class="btn btn-secondary bg-patreon btn-block">
            <fa-icon [icon]="faIcons.patreon"></fa-icon>
            <span class="ml-1">Patreon</span>
          </a>
          <a href="/guilds"
             target="_blank" class="btn btn-secondary bg-paypal btn-block">
            <fa-icon [icon]="faIcons.paypal"></fa-icon>
            <span class="ml-1">PayPal</span>
          </a>
          <p class="card-text mt-2">
            Donating will also provide you some additional features. For details please check out the
            <a routerLink="/premium" class="card-link">premium page</a>.
          </p>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-3">
      <div class="card">
        <div class="card-header">
          <h3>Follow:</h3>
          <p>Get the latest information about the development!</p>
        </div>
        <div class="card-body">
          <a href="https://discord.com/invite/cVzMUKX" target="_blank" class="btn btn-secondary bg-discord btn-block">
            <fa-icon [icon]="faIcons.discord"></fa-icon>
            <span class="ml-1">Join our official Discord server</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
