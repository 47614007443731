<header class="d-flex flex-md-row pl-5 pb-2 pt-3 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <ng-container *ngIf="guild; else loadingHeader" >
      <h1 class="bd-title mt-0">
        <img *ngIf="guild.id && guild.icon; else defaultIcon" class="rounded-circle ms-2 d-none d-sm-inline" alt="guild-icon" src="https://cdn.discordapp.com/icons/{{ guild.id }}/{{ guild.icon }}.png?size=64" />
        <i class="guild-name-dark">{{ guild?.name }}</i>
      </h1>
    </ng-container>
    <p class="bd-lead">
      <button class="cssbuttons-io-button bd-lead" routerLink="/guilds"> Guild Selection
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path fill="currentColor" d="M7.828 11l5.364-5.364-1.414-1.414L4 12l7.778 7.778 1.414-1.414L7.828 13H20v-2z"></path>
          </svg>
        </div>
      </button>
    </p>
  </div>
  <ng-container *ngIf="guild && !guild.has_premium">
    <div class="image-text-box pr-5">
      <div class="description" *ngIf="showDescription" [innerHTML]="ads[ads_nr].description"
           (mouseenter)="enterDescription()" (mouseleave)="leaveDescription()"></div>
      <a href="{{ ads[ads_nr].url }}" target="_blank" rel="noopener">
        <div class="image-container">
          <img class="image" alt="{{ ads[ads_nr].name }}" src="{{ ads[ads_nr].image }}"
               (mouseenter)="enterImage()" (mouseleave)="leaveImage()"/>
        </div>
      </a>
    </div>
  </ng-container>
</header>

<ng-container *ngIf="guild">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8" mode="side" opened>
      <mat-accordion class="example-headers-align" multi>
        <mat-nav-list *ngIf="guild.channels.length > 0">
          <a mat-list-item routerLink="event/create" class="add-entry-button-enabled">
            <fa-icon [icon]="faIcon.add"></fa-icon> Create Event
          </a>
        </mat-nav-list>
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              General
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item routerLink="overview">Overview</a>
            <a mat-list-item routerLink="server">Server Settings</a>
            <mat-expansion-panel [expanded]="channelSelected">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Channel Settings
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list *ngFor="let channel of guild?.channels">
                <a mat-list-item routerLink="channel/{{channel.id}}">{{channel.name}}</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <a mat-list-item routerLink="user">User Settings</a>
            <a mat-list-item routerLink="gears">Gear Exchange</a>
            <a mat-list-item routerLink="premium">Premium</a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Signup Templates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a mat-list-item *ngFor="let signup_template of guild?.signup_templates"
              [disabled]="!guild.owner"
              [appDisableLink]="!guild.owner"
              [routerLink]="'signup-template/' + signup_template.id">
              {{signup_template.name}}
            </a>
            <a mat-list-item
              [class.add-entry-button-disabled]="!guild.owner || (guild.signup_templates.length >= guild.limits.max_custom_templates && !guild.has_premium)"
              [class.add-entry-button-enabled]="guild.owner && (guild.signup_templates.length < guild.limits.max_custom_templates || guild.has_premium)"
              [appDisableLink]="!guild.owner || (guild.signup_templates.length >= guild.limits.max_custom_templates && !guild.has_premium)"
              [disabled]="!guild.owner || (guild.signup_templates.length >= guild.limits.max_custom_templates && !guild.has_premium)"
              routerLink="signup-template/new"
              [matTooltip]="guild.owner && (guild.signup_templates.length >= guild.limits.max_custom_templates && !guild.has_premium) ? 'Maximum number of custom signup templates exceeded' : ''"
              [matTooltipPosition]="'below'">
              <fa-icon [icon]="faIcon.add"></fa-icon> Add Entry
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <div class="top-left-corner">
          <button *ngIf="sidenav.mode === 'over'" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()">
            <fa-icon [icon]="faIcon.menubar"></fa-icon>
          </button>
        </div>
        <div class="card-content m-3 ml-xs-0 ml-md-4">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
</ng-container>

<ng-template #defaultIcon>
  <img class="card-img-top" class="rounded-circle ms-2 d-none d-sm-inline" alt="guild-icon" src="https://cdn.discordapp.com/icons/{{ raidOrganizerGuild.id }}/{{ raidOrganizerGuild.icon }}.png?size=64">
</ng-template>

<ng-template #loadingHeader>
  <h1 class="bd-title mt-0">
    <div class="content_loader">
      <div class="content_loader_wrapper">
        <div class="content_loader_server_icon rounded-circle ms-2 "></div>
        <div class="content-loader-line-1"></div>
      </div>
    </div>
  </h1>
</ng-template>
