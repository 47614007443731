import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from 'src/app/app.component';
import { BotGuild } from 'src/app/models/bot/guild';
import { GuildService } from 'src/app/services/guild.service';

declare const Paddle: any;
interface ShopItems {
  price: number;
  id: string;
  viewValue: string;
  amount?: number;
  months?: number;
  internal: string;
}

@Component({
  selector: 'app-fastspring-card',
  templateUrl: './fastspring-card.component.html',
  styleUrls: ['./fastspring-card.component.css']
})
export class FastspringCardComponent implements OnInit {

  guildId: string;
  userId: number;
  userEmail: string;
  guild: BotGuild | undefined;

  faIcon = {
    shoppingCart: faShoppingCart,
  };

  monthlyPremiumShopItems: ShopItems[] = [
    // { price: 5, id: 'pri_01hjp7xcs7eqraf2v5sn14d9qj', viewValue: '1 Month Premium', internal: '1-month-premium' },  // sandbox
    // { price: 20, id: 'pri_01hjp7yr39z8npvmvaxysesq4a', viewValue: '6 Months Premium', internal: '6-months-premium' }, // sandbox
    { price: 5, id: 'pri_01hjtw1z2904nsvvm52p2ba2za', viewValue: '1 Month Premium', internal: '1-month-premium' },
    { price: 20, id: 'pri_01hjtxnjpgk1zp3feqnd2bg6hj', viewValue: '6 Months Premium', months: 6, internal: '6-months-premium' },
    { price: 38, id: 'pri_01hjtxpeanwgms957r4zwsxcwf', viewValue: '12 Months Premium', months: 12, internal: '12-months-premium' },
  ];

  lifetimePremiumShopItems: ShopItems[] = [
    { price: 80, id: 'pri_01hjtxqk831wxb35xtf596xd9y', viewValue: 'Lifetime Premium *', internal: 'lifetime-premium' },
  ];

  premiumShopItems: ShopItems[] = this.monthlyPremiumShopItems.concat(this.lifetimePremiumShopItems);

  limitShopItems: ShopItems[] = [
    { price: 5, id: 'pri_01hjtxvtbkvwm32nv14var5zkv', viewValue: '+5 Event Channels', internal: '5-event-channels' },
    { price: 5, id: 'pri_01hjtxx5z8xgbc4pryj5pkvkd2', viewValue: '+5 Repeating Events', internal: '5-repeating-events' },
    { price: 5, id: 'pri_01hjty6wbrw6vss6vj0wpw8k8m', viewValue: '+3 Event Templates', internal: '3-event-templates' },
    { price: 5, id: 'pri_01hjty7xt391qdxg8zkq2eyyhf', viewValue: '+1 Signup Template', internal: '1-signup-template' },
    { price: 10, id: 'pri_01hjtye9p4wgpp62kn2zp4k5ze', viewValue: '+2 Event Channels, +2 Repeating Events, +2 Signup Templates', internal: '2-signup-templates-2-event-channels-2-repeating-events' },
    { price: 15, id: 'pri_01hjtytrz2k3ywx2qapem6mngv', viewValue: '+3 Event Channels, +3 Repeating Events, +3 Signup Templates', internal: '3-signup-templates-3-event-channels-3-repeating-events' },
  ];

  shopItems: ShopItems[] = this.premiumShopItems.concat(this.limitShopItems);

  checkoutList: ShopItems[] = [];
  checkoutListLength: number = 0;
  priceTotal: string;

  constructor(
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    public guildService: GuildService,
    ) { }

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });
  }

  remove_premium_items_from_cart() {
    this.checkoutList = this.checkoutList.filter(item => item.internal !== '1-month-premium');
    this.checkoutList = this.checkoutList.filter(item => item.internal !== '6-months-premium');
    this.checkoutList = this.checkoutList.filter(item => item.internal !== '12-months-premium');
  }

  remove_lifetime_premium_from_cart() {
    this.checkoutList = this.checkoutList.filter(item => item.internal !== 'lifetime-premium');
  }

  addItemToCart(id: string) {
    // This function is called when the user clicks on the "Add to cart" button
    // It checks if the item is already in the cart, and if it is, it increases the quantity
    // If it isn't, it adds the item to the cart
    // It also updates the cart length
    let item = this.shopItems.find(item => item.id === id);
    if (item.internal === 'lifetime-premium') {
      this.remove_premium_items_from_cart();
    }
    if (item.internal === '1-month-premium' || item.internal === '6-months-premium' || item.internal === '12-months-premium') {
      this.remove_lifetime_premium_from_cart();
    }
    let itemInCart = this.checkoutList.find(item => item.id === id);
    if (itemInCart) {
      itemInCart.amount++;
    }
    else {
      item.amount = 1;
      this.checkoutList.push(item);
    }
    this.checkoutListLength = this.itemCartLength();
    this.calculateTotal();
    // console.log(this.checkoutList);
  }

  serverHasLifetimePremium() {
    // This function checks if the server either has lifetime premium active or
    // if the cart currently contains lifetime premium
    // If it does, it returns true, otherwise it returns false
    if (this.guild) {
      if (this.guild.lifetime_premium) {
        return true;
      }
    }
    if (this.checkoutList.find(item => item.internal === 'lifetime-premium')) {
      return true;
    }
    return false;
  }

  removeItemFromCart(id: string) {
    // This function is called when the user clicks on the "Remove from cart" button
    // It checks if the item is already in the cart, and if it is, it decreases the quantity
    // If the quantity is 0, it removes the item from the cart
    // It also updates the cart length
    let itemInCart = this.checkoutList.find(item => item.id === id);
    if (itemInCart) {
      itemInCart.amount--;
      if (itemInCart.amount == 0) {
        this.checkoutList = this.checkoutList.filter(item => item.id !== id);
      }
    }
    this.checkoutListLength = this.itemCartLength();
    this.calculateTotal();
    // console.log(this.checkoutList);
  }

  calculateTotal() {
    // This function calculates the total price of the cart
    // If the price is 0, priceTotal is set to undefined
    // This is used to hide the 0 nexto the "Checkout" button
    let total = 0;
    this.checkoutList.forEach(item => {
      total += item.price * item.amount;
    });
    if (total == 0) {
      this.priceTotal = undefined;
    }
    else {
      this.priceTotal = total + " EUR";
    }
  }

  itemCartLength(): number {
    return Object.keys(this.checkoutList).length;
  }

  clearCart() {
    // This function is called when the user clicks on the "Clear cart" button
    // It empties the cart and updates the cart length
    this.checkoutList = [];
    this.checkoutListLength = this.itemCartLength();
    this.calculateTotal();
  }

  popupStoreFront() {
    // This function is called when the user clicks on the "Checkout" button
    // It uses the FastSpring builder API to open the popup store
    // It also adds the items in the cart to the popup store
    // It also adds the tags to the popup store
    // It also resets the popup store
    if (typeof Paddle !== 'undefined') {

      var products = [];
      this.checkoutList.forEach(item => {
        products.push({ "priceId": item.id, "quantity": item.amount });
      });
      this.userId = this.appComponent.userId;
      this.userEmail = this.appComponent.email;

      if (this.userId == undefined || this.guildId == undefined) {
        console.log('User or guild not defined');
        return;
      }

      const checkoutConfig = {
        items: products,
        customData: {
          "user_id": this.userId.toString(),
          "guild_id": this.guildId.toString(),
        }
      };

      if (typeof this.userEmail === 'string' && this.userEmail.length > 0) {
        checkoutConfig['customer'] = {
          email: this.userEmail,
        };
      }

      Paddle.Checkout.open(checkoutConfig);

    }
  }
}
