<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4">
    <h1 class="bd-title mt-0">Privacy information</h1>
    <p class="bd-lead">What data do we store, why do we store it and how is it secured?</p>
  </div>
</header>

<section class="p-5 guild-bg-dark">
  <h2>First of all:</h2>
  <p>
    Security and data safety is important to us. Also the privacy of our users.
    That is why we try to store as little data as possible to operate our service.
  </p>
  <h2>What data do we store and why?</h2>
    <u>User specific data:</u>
    <ul>
      <li>
        Your discord user id.
        <ul>
          <li>
            To update your reaction in an event (and not the one from someone else 😉).
          </li>
        </ul>
      </li>
      <li>
        Which events you signed up for and which reaction you used.
        <ul>
          <li>
            To be able to show you in which events you attended, to allow admins to confirm you and to restore events if the bot restarts.
          </li>
        </ul>
      </li>
      <li>
        Which events are created by you.
        <ul>
          <li>
            Needed for the "LFG" feature to work. In this feature only the event creator is able to edit it's own event.
          </li>
        </ul>
      </li>
    </ul>

  <h2>How do we use your data?</h2>
  <p>We use this data to:</p>
  <ul>
    <li>Display the events to all users that have permissions to read in that channel.</li>
    <li>Perform certain checks, for example if you have the right to modify a certain LFG event.</li>
  </ul>

  <h2>How do we store your data?</h2>
  <p>
    We store the data on a server in Frankfurt. Authentication to the server is only allowed via SSH-Key.<br>
    The data itself is stored in a password protected database.
  </p>

  <h2>Can I delete my data?</h2>
  <p>
    In short: <b>Yes!</b><br>
    However, we want to point out that your data is crucial for the bot to operate correctly. If you, for what ever reason, decide that
    you want your data deleted:
  </p>
    <ul>
      <li>
        you will not be signed up for events anymore (your name will be removed from those events)
      </li>
      <li>
        your configs will be lost
      </li>
      <li>etc.</li>
    </ul>
  <p>
    That said; If you want that we erase your data you have the following options:
  </p>
  <ul>
    <li>
      Leave the discord server in which the bot is operating. (The bot auto removes users that are leaving a server.)
    </li>
    <li>
      Ask an admin of the server to remove the "user role" from you. (The bot auto removes users that do not have the user role.)
    </li>
    <li>
      Send us a request. We will remove all your entries from the database. However, this option might take some time since we need
      to manually trigger this action (up to 5 working days).<br>Also this will only remove your ID from our database. It will
      <b>not</b> refresh events that you have signed up for! This means that you are listed in those events until the event
      gets reloaded (for example a new user signs up).
    </li>
  </ul>

  <h2>Questions?</h2>
  <p>
    If you have any questions about the data protection rights, feel free to contact us via:<br>
    E-Mail: <a href="mailto:admin@raidorganizer.org">admin@raidorganizer.org</a><br>
    Discord Server: <a href="https://discord.com/invite/cVzMUK" target="_blank">Raid Organizer</a>
  </p>
</section>
