<div class="container-fluid">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <h1>User Settings</h1>
    </div>
  </div>
  <ng-container *ngIf="guild && userInfo$ | async as userInfo">
    <div class="toggleSubscribeConfirmMessage">
      <mat-slide-toggle [checked]="userInfo.get_confirmed_message"
                        (change)="toggleSubscribeConfirmMessage()">
        Get a DM when you are confirmed for an event
        <!-- TODO: Doc and cooldown -->
        <small class="form-text text-muted">/toggle_subscribe_confirm_message</small>
      </mat-slide-toggle>
    </div>
  </ng-container>
</div>
