import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DiscordEmbed, DiscordEmoji, EmbedField } from '../components/embed-builder/embed.model';

@Injectable({ providedIn: 'root' })
export class EmbedService {
  private embedSource = new BehaviorSubject<DiscordEmbed>({ fields: [] });
  currentEmbed = this.embedSource.asObservable();
  private emojiSource = new BehaviorSubject<DiscordEmoji[]>([]);
  currentEmojis = this.emojiSource.asObservable();

  constructor() {}

  initializeEmbed(embed: DiscordEmbed) {
    this.embedSource.next(embed);
  }

  updateEmbed(embed: Partial<DiscordEmbed>) {
    const current = this.embedSource.value;
    this.embedSource.next({ ...current, ...embed });
  }

  addField(field: Partial<EmbedField>) {
    const current = this.embedSource.value;
    const fields = [...current.fields, field as EmbedField];
    this.embedSource.next({ ...current, fields });
  }

  removeField(index: number) {
    const current = this.embedSource.value;
    const fields = current.fields.filter((_, i) => i !== index);
    this.embedSource.next({ ...current, fields });
  }

  updateEmojis(emojis: DiscordEmoji[]) {
    this.emojiSource.next(emojis);
  }
}
