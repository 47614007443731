import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { HelpComponent } from './pages/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { CommandsComponent } from './pages/help/commands/commands.component';
import { GettingStartedComponent } from './pages/getting-started/getting-started.component';
import * as env from '../environments/environment';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SupportComponent } from './pages/support/support.component';
import { MarkdownComponent } from './pages/help/commands/markdown/markdown.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GuildCardsComponent } from './pages/guild-selection/guild-cards/guild-cards.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FindEventPipe } from './pipes/find-event.pipe';
import { PremiumComponent } from './pages/premium/premium.component';
import { StatisticsComponent } from './pages/home/statistics/statistics.component';
// import { NgxObserveModule } from 'ngx-observe';
import { NgxPayPalModule } from 'ngx-paypal';
import { GuildSelectionComponent } from './pages/guild-selection/guild-selection.component';
// import { DarkModeComponent } from './dark-mode/dark-mode.component';
import { GuildDashboardComponent } from './pages/guild-dashboard/guild-dashboard.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule} from '@angular/material/icon';
import { MatDividerModule} from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';import { OverviewCardComponent } from './pages/guild-dashboard/overview-card/overview-card.component';
import { ServerCardComponent } from './pages/guild-dashboard/server-card/server-card.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChannelCardComponent } from './pages/guild-dashboard/channel-card/channel-card.component';
import { UserCardComponent } from './pages/guild-dashboard/user-card/user-card.component';
import { GearCardComponent } from './pages/guild-dashboard/gear-card/gear-card.component';
import { FastspringCardComponent } from './pages/guild-dashboard/fastspring-card/fastspring-card.component';
import { PaypalCardComponent } from './pages/guild-dashboard/paypal-card/paypal-card.component';
import { NgxColorsModule } from 'ngx-colors';
import { DurationPipe } from './pipes/duration.pipe';
import { SignupTemplateCardComponent } from './pages/guild-dashboard/signupTemplate-card/signupTemplate-card.component';
import { EmbedBuilderComponent } from './components/embed-builder/embed-builder.component';
import { EmbedService } from './services/embed.service';
import { EmbedPreviewComponent } from './components/embed-preview/embed-preview.component';
import { SignupTemplateBuilderComponent } from './components/signup-template-builder/signup-template-builder.component';
import { MarkdownModule } from 'ngx-markdown';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiPickerButtonComponent } from './components/emoji-picker-button/emoji-picker-button.component';
import { DisableLinkDirective } from './directives/disable-link.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EventCardComponent } from './pages/guild-dashboard/event-card/event-card.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HelpComponent,
    HomeComponent,
    CommandsComponent,
    GettingStartedComponent,
    AboutComponent,
    TermsComponent,
    PrivacyComponent,
    SupportComponent,
    MarkdownComponent,
    GuildCardsComponent,
    FindEventPipe,
    PremiumComponent,
    StatisticsComponent,
    GuildSelectionComponent,
    // DarkModeComponent,
    GuildDashboardComponent,
    OverviewCardComponent,
    ServerCardComponent,
    ChannelCardComponent,
    SignupTemplateCardComponent,
    EventCardComponent,
    UserCardComponent,
    GearCardComponent,
    FastspringCardComponent,
    PaypalCardComponent,
    DurationPipe,
    EmbedBuilderComponent,
    EmbedPreviewComponent,
    SignupTemplateBuilderComponent,
    EmojiPickerButtonComponent,
    DisableLinkDirective,
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          env.environment.baseUrlDiscord,
          'api',
        ],
        sendAccessToken: true
      },
    }),
    ToastrModule.forRoot(),
    FontAwesomeModule,
    // NgxObserveModule,
    NgxPayPalModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTreeModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NgxColorsModule,
    PickerModule,
    DragDropModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule
  ],
  providers: [
    EmbedService
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [EmojiPickerButtonComponent, DisableLinkDirective]
})
export class AppModule { }
