<div class="embed-builder">
  <mat-form-field appearance="fill">
    <mat-label>Title:</mat-label>
    <input matInput [(ngModel)]="embed.title" (ngModelChange)="updateEmbed({title: $event})" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Description:</mat-label>
    <textarea matInput [(ngModel)]="embed.description" (ngModelChange)="updateEmbed({description: $event})"></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Thumbnail URL:</mat-label>
    <input matInput [(ngModel)]="embed.thumbnailUrl" (ngModelChange)="updateEmbed({thumbnailUrl: $event})" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Color (Hex):</mat-label>
    <input matInput [(ngModel)]="embed.color" (ngModelChange)="updateEmbed({color: $event})" />
  </mat-form-field>

  <div *ngFor="let field of embed.fields; let i = index">
    <mat-form-field appearance="fill">
      <mat-label>Field Name:</mat-label>
      <input matInput [(ngModel)]="field.name" (ngModelChange)="field.name = $event; updateEmbed({})" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Field Value:</mat-label>
      <input matInput [(ngModel)]="field.value" (ngModelChange)="field.value = $event; updateEmbed({})" />
    </mat-form-field>
    <mat-checkbox [(ngModel)]="field.inline" (ngModelChange)="field.inline = $event; updateEmbed({})">Inline</mat-checkbox>
    <button mat-button mat-raised-button color="primary" (click)="removeField(i)">Remove Field</button>
  </div>
  <button mat-raised-button (click)="addField()">Add Field</button>
</div>
