import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BotGuild, UserConfig } from 'src/app/models/bot/guild';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { GuildService } from 'src/app/services/guild.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {
  guildId: string;
  guild: BotGuild;

  @Input() userInfo$: Observable<UserConfig>;


  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
  ) { }

  ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');
    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });
    this.userInfo$ = this.botService.getGuildUser(this.guildId).pipe(
      shareReplay(1)
    );
  }

  toggleSubscribeConfirmMessage(): void {
    this.botService.sendCommand(this.guildId, 'toggle_subscribe_confirm_message', '');
  }
}
