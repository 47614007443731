<div class="container-fluid text-center">
  <div class="row pt-3 ml-3">
    <div class="col d-flex justify-content-center">
      <h1>Statistics</h1>
    </div>
  </div>
  <div class="row pt-2 justify-content-md-center">
    <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center">
      <mat-card class="overview-card events-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.calendarCheck" class="m-auto"></fa-icon>
              Events Created
            </mat-card-title>
            <mat-card-subtitle>Total</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.events_created}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-center">
      <mat-card class="overview-card user-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.users" class="m-auto"></fa-icon>
              User Sign Ups
            </mat-card-title>
            <mat-card-subtitle>Total</mat-card-subtitle>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.user_signups}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col d-flex justify-content-center">
      <h1>Limits</h1>
    </div>
  </div>
  <div class="row justify-content-center pt-2">
    <div class="col-md-12 col-xl-3 col-lg-6 mt-3 mt-xl-0 d-flex justify-content-center">
      <mat-card class="overview-card channel-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.list" class="m-auto"></fa-icon>
              Event Channels
            </mat-card-title>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.usage.curr_event_channels}} / {{overview.limits.max_event_channels}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-xl-3 col-lg-6 mt-3 mt-xl-0 d-flex justify-content-center">
      <mat-card class="overview-card repeating-entries-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.repeat" class="m-auto"></fa-icon>
              Repeating Events
            </mat-card-title>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.usage.curr_repeating_events}} / {{overview.limits.max_repeating_events}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-xl-3 col-lg-6 mt-3 mt-xl-0 d-flex justify-content-center">
      <mat-card class="overview-card signup-templates-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.users" class="m-auto"></fa-icon>
              Signup Tempates
            </mat-card-title>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.usage.curr_signup_templates}} / {{overview.limits.max_custom_templates}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-12 col-xl-3 col-lg-6 mt-3 mt-xl-0 d-flex justify-content-center">
      <mat-card class="overview-card repeating-entries-card">
        <mat-card-header class="">
          <mat-card-title-group>
            <mat-card-title>
              <fa-icon [icon]="faIcons.calendaPlus" class="m-auto"></fa-icon>
              Event Templates
            </mat-card-title>
          </mat-card-title-group>
        </mat-card-header>
        <mat-card-content *ngIf="overview$ | async as overview else defaultCount">
          {{overview.usage.curr_event_templates}} / {{overview.limits.max_event_templates}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-12 d-flex justify-content-center">
      <h1>Upcoming Events</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <h4 *ngIf="guild && !guild.has_premium"><i>Premium Feature</i></h4>
    </div>
  </div>

  <div class="row justify-content-center pt-2">
    <div class="col-12 d-flex justify-content-center">
      <!-- Guild has premium -->
      <div *ngIf="guild?.has_premium" class="full-width-container">
        <div *ngIf="overview$ | async as overview">
          <mat-table [dataSource]="overview.upcoming_events" class="overview-card mat-elevation-z8">
            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
              <mat-cell *matCellDef="let event"><a class="mat-select-link" href="https://discord.com/channels/{{guildId}}/{{event.channel_id}}/{{event.message_id}}" target="_blank">{{ event.title }}</a></mat-cell>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
              <mat-cell *matCellDef="let event">{{ event.start }}</mat-cell>
            </ng-container>

            <!-- Sign Ups Column -->
            <ng-container matColumnDef="sign_ups">
              <mat-header-cell *matHeaderCellDef>Sign Ups</mat-header-cell>
              <mat-cell *matCellDef="let event">{{ event.sign_ups }}</mat-cell>
            </ng-container>

            <!-- Signed Up Column -->
            <ng-container matColumnDef="signed_up">
              <mat-header-cell *matHeaderCellDef>Signed Up</mat-header-cell>
              <mat-cell *matCellDef="let event">
                <fa-icon *ngIf="event.signed_up" [icon]="faIcons.check" class="m-auto check_green"></fa-icon>
                <fa-icon *ngIf="!event.signed_up" [icon]="faIcons.cross" class="m-auto check_red"></fa-icon>
              </mat-cell>
            </ng-container>

            <!-- Table Header Row -->
            <mat-header-row *matHeaderRowDef="['title', 'date', 'sign_ups', 'signed_up']"></mat-header-row>
            <!-- Table Row -->
            <mat-row *matRowDef="let event; columns: ['title', 'date', 'sign_ups', 'signed_up']"></mat-row>
          </mat-table>
        </div>
      </div>
      <div *ngIf="!guild || !guild?.has_premium" class="full-width-container">
        <mat-table [dataSource]="dummyArray" class="overview-card mat-elevation-z8">
          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
            <mat-cell *matCellDef="let event" class="content_loader">
              <div class="content_loader_wrapper">
                <div class="content-loader-line-1"></div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
            <mat-cell *matCellDef="let event" class="content_loader">
              <div class="content_loader_wrapper">
                <div class="content-loader-line-1"></div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Sign Ups Column -->
          <ng-container matColumnDef="sign_ups">
            <mat-header-cell *matHeaderCellDef>Sign Ups</mat-header-cell>
            <mat-cell *matCellDef="let event" class="content_loader">
              <div class="content_loader_wrapper">
                <div class="content-loader-line-1"></div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Signed Up Column -->
          <ng-container matColumnDef="signed_up">
            <mat-header-cell *matHeaderCellDef>Signed Up</mat-header-cell>
            <mat-cell *matCellDef="let event" class="content_loader">
              <div class="content_loader_wrapper">
                <div class="content-loader-line-1"></div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- Table Header Row -->
          <mat-header-row *matHeaderRowDef="['title', 'date', 'sign_ups', 'signed_up']"></mat-header-row>
          <!-- Table Row -->
          <mat-row *matRowDef="let event; columns: ['title', 'date', 'sign_ups', 'signed_up']"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultCount>
  <mat-card-content>0</mat-card-content>
</ng-template>
