<div class="pt-3 ml-3 text-center">
  <h1>Shop</h1>
</div>

<div class="row">
  <div class="col-md-12 col-lg-7">
    <h3 class="text-center">Go Premium</h3>
    <ng-container *ngFor="let item of premiumShopItems">
      <div class="row">
        <div class="col-10">
          {{ item.viewValue }}
        </div>
        <div class="col-2  d-flex justify-content-center">
          <button mat-button color="primary" (click)="addItemToCart(item.id)" [disabled]="item.id === 'lifetime-premium' && serverHasLifetimePremium()">+</button>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          {{ item.price }} EUR <p class="ppm" *ngIf="item.months">({{ (item.price / item.months).toFixed(2) }} EUR/month)</p>
        </div>
        <div class="col-2  d-flex justify-content-center">
          <button mat-button color="accent" (click)="removeItemFromCart(item.id)">-</button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <h3 class="text-center mt-3">Increase Limits</h3>
    <ng-container *ngFor="let item of limitShopItems">
      <div class="row">
        <div class="col-10">
          {{ item.viewValue }}
        </div>
        <div class="col-2  d-flex justify-content-center">
          <button mat-button color="primary" (click)="addItemToCart(item.id)">+</button>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          {{ item.price }} EUR
        </div>
        <div class="col-2  d-flex justify-content-center">
          <button mat-button color="accent" (click)="removeItemFromCart(item.id)">-</button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
  </div>

  <mat-divider [vertical]="true"></mat-divider>

  <div class="col-md-12 col-lg-4 mt-md-0 mt-3">
    <h3><fa-icon [icon]="faIcon.shoppingCart"></fa-icon> Checkout</h3>
    <div *ngIf="checkoutListLength > 0; else emptyCart">
      <div *ngFor="let item of checkoutList">
        <code>{{ item.amount }}x</code> {{ item.viewValue }}
      </div>
    </div>
    <div>
      <button mat-button class="full-width-button mt-2" mat-raised-button color="primary" [disabled]="checkoutListLength === 0" (click)="popupStoreFront()">Checkout {{ priceTotal }}</button>
      <button mat-button class="full-width-button mt-2" mat-raised-button color="accent" [disabled]="checkoutListLength === 0" (click)="clearCart()">Clear Cart</button>
      <ng-container *ngIf="checkoutListLength > 0">
        <div class="text-center mt-2">
          <i>Price without VAT</i>
        </div>
      </ng-container>
    </div>
    <ng-template #emptyCart>
      Your cart is empty!
    </ng-template>
  </div>
</div>
<div class="row mt-3">
  <div class="col">
<b>Notes:</b>
<ul>
  <li>All payments are one time payments! If you want a <i>Premium</i> subscription, please use <a href="https://www.patreon.com/raid_organizer" target="_blank">Patreon</a>.</li>
  <li>Increased limits will stay forever*.</li>
  <li>All payments give you the <i>Donator</i> role on our <a href="https://discord.raidorganizer.org" target="_blank">Discord server</a>.</li>
</ul>

* <i>forever</i> means as long as the service is running and you do not delete your data! As you might know, all things come to an end at some point.
I do not have any plans to shut down the service, but we cannot guarantee anything. There might be changes to the Discord API, unexpected events to my person
or other things that might force me to shut down the service.



<!-- TODO: NOTE: Add data remove info to docs -->
</div>
</div>
