<header class="d-flex flex-column flex-md-row align-items-md-center p-5 title-bg-dark">
  <div class="pt-md-3 pb-md-4 w-50">
    <h1 class="bd-title mt-0">Go Premium</h1>
    <p class="bd-lead">
      Upgrade RaidOrganizer to Premium or become a Patreon does
      support the development of RaidOrganizer as well as giving you additional features!
    </p>
  </div>
</header>

<section class="p-5 guild-bg-dark">
  <div class="row justify-content-center">
    <div class="col-xl-8 col-lg-12">
      <nav>
        <div class="nav nav-tabs nav-justified" id="nav-premium" role="tablist" style="border:0px">
          <button class="nav-link bg-discord rounded-top-left-05 active" id="nav-votes-tab" data-toggle="tab" data-target="#nav-votes" type="button" role="tab" aria-controls="nav-votes" aria-selected="true">Via Voting ⛭</button>
          <button class="nav-link bg-paypal rounded-0" id="nav-paypal-tab" data-toggle="tab" data-target="#nav-paypal" type="button" role="tab" aria-controls="nav-paypal" aria-selected="false">Via One-Time Payment <fa-icon [icon]="faIcons.paypal"></fa-icon></button>
          <button class="nav-link bg-patreon rounded-top-right-05" id="nav-patreon-tab" data-toggle="tab" data-target="#nav-patreon" type="button" role="tab" aria-controls="nav-patreon" aria-selected="false">Via Subscription <fa-icon [icon]="faIcons.patreon"></fa-icon></button>
        </div>
      </nav>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-xl-8 col-lg-12">
      <div class="tab-content" id="nav-premium">
        <div class="tab-pane fade show active" id="nav-votes" role="tabpanel" aria-labelledby="nav-votes-tab">
          <div class="card-header bg-discord rounded-0">
            <h3>Premium via Voting</h3>
            <p>Get Premium features via Voting for the Raid Organizer</p>
          </div>
          <div class="card-body d-flex flex-column tab-content" id="nav-tabContent">
            <p>
              You can get all Premium features for free as I do not want to exclude anyone from enjoying the nice Premium
              features <span class="text-info">Raid Organizer</span> offers. All you have to do is to vote for the bot.
            </p>

            <h3>How do I get gears?</h3>
            <p>You can get gears by:</p>
            <ul>
              <li>
                Voting for the bot (and get 1 gear for each vote)
                <div>
                  <a href="https://top.gg/bot/658698521570181140" target="_blank"
                     role="button" class="btn btn-secondary bg-discord px-3 my-1 mr-2">
                    <fa-icon [icon]="faIcons.discord"></fa-icon>
                    <span class="ml-1">top.gg</span>
                  </a>
                  <a href="https://discordbotlist.com/bots/658698521570181140" target="_blank"
                     class="btn btn-secondary bg-discord px-3 my-1">
                    <fa-icon [icon]="faIcons.discord"></fa-icon>
                    <span class="ml-1">discordbotlist.com</span>
                  </a>
                </div>
              </li>
            </ul>

            <h3>How do I get Premium features?</h3>
            <p>
              Head over to the <a href="https://discordapp.com/channels/658007285205041153/705729325915177082/707992173664010261">
              gear-exchange</a> channel on our <a href="https://discord.raidorganizer.org">Discord-Server</a> and use the following command:
            </p>
            <ul>
              <li><code>/gears guild consume &#60;guild_id&#62;</code></li>
            </ul>

            <h3>How do I move gears to my guild/server?</h3>
            <p>
              After you or your guild mates voted for the bot, the gears are "in your wallet".
              You can check how many gears you have by using the <code>/gears user amount</code> in the <i>gear-exchange</i> channel.
            </p>
            <p>
              You can now move the gears from your balance to a guild balance of your choice by using the command
              <code>/gears user contribute &#60;guild id&#62; &#60;gear amount&#62;</code> (you can get the guild id by using the
              <code>/gears guild share</code> command).
            </p>
            <p>
              Every user in your server can vote and transfer gears to your server. This means if you have 10
              users voting each day, you can get 20 votes each day!
            </p>

            <h3>Doing it via homepage</h3>
            <p>You can also:</p>
            <ul>
                <li>Move gears from the user balance to the guild balance</li>
                <li>Exchange gears for Premium/raising limits</li>
              </ul>
              on the homepage. Just login, head over to "Manage Servers", select your server and click the "Gear Exchange" tab.
          </div>
        </div>
        <div class="tab-pane fade" id="nav-paypal" role="tabpanel" aria-labelledby="nav-paypal-tab">
          <div class="card-header bg-paypal rounded-0">
            <h3>Premium via One-Time Payment</h3>
            <p>Get Premium features via PayPal, Credit Card, etc. + a <i>Donator</i> role on Discord</p>
          </div>
          <div class="card-body d-flex flex-column tab-content" id="nav-tabContent">
            <p>
              You decide to use money to get Premium? Thank you for that! The money I get from Premium helps me to
              pay the servers, promote the bot and to quench my Vienna Schnitzel addiction :D
            </p>
            <p>
              For the payment I use <i>Paddle</i> as a payment provider. They handle the payment, fraud protection, Taxes, payment optitons and so on.
            </p>
            <p>
              All payments via <i>Paddle</i> are one-time payments. This means you do not have to worry about canceling a subscription. If you prefer
              a subscription, please use Patreon.
            </p>
            <p>To get Premium:</p>
            <ul>
              <li>Login to the webpage (top right corner)</li>
              <li>Head over to "Manage Servers"</li>
              <li>Choose your server from the list</li>
              <li>Select the "Premium" tab</li>
            </ul>
            <p><i>Note:</i> You can get a 1 Week Premium trial for free at <a href="https://www.patreon.com/raid_organizer" target="_blank"
                class="btn btn-secondary bg-patreon px-3 my-1">
                <fa-icon [icon]="faIcons.patreon"></fa-icon>
                <span class="ml-1">Patreon</span>
              </a>. Please note that after the free trial you will be charged for the next month if you do not cancel the subscription!
            </p>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-patreon" role="tabpanel" aria-labelledby="nav-patreon-tab">
          <div class="card-header bg-patreon rounded-0">
            <h3>Premium via Subscription</h3>
            <p>Get Premium via a Patreon subscription + Patreon exclusive stuff</p>
          </div>
          <div class="card-body d-flex flex-column tab-content" id="nav-tabContent">
            <p>
              You decide to use Patreon to get Premium? Thank you for that! The money I get from Premium helps me to
              pay the servers, promote the bot and to quench my Vienna Schnitzel addiction :D
            </p>
            <p>Note: Patreon is a monthly subscription! If you do not like subscriptions, please use <i>Paddle</i> as a one-time option!</p>
            <h3>How do I become a Patreon?</h3>
            <p>
              Head over to
              <a href="https://www.patreon.com/raid_organizer" target="_blank"
                 class="btn btn-secondary bg-patreon px-3 my-1">
                <fa-icon [icon]="faIcons.patreon"></fa-icon>
                <span class="ml-1">Patreon</span>
              </a> and select one of the available tears.
            </p>

            <h3>I have payed for Patreon. How do I activate it?</h3>
            <p>
              Once everything is verified by Patreon, <span class="text-info">Raid Organizer</span> will send you a DM with your Patreon keys.<br/>
              To assign the keys to a server, use the <code>/patreon</code> commands.
              You can also find additional help <a href="https://docs.raidorganizer.org/premium/patreon/assign_key" target="_blank">here</a>.
            </p>

            <h3>I have payed for Patreon but I do not have access to the features!</h3>
            <p>
              If you have payed for the membership, the payment is verified and sent, please check if the following is true:
            </p>
            <ul>
              <li>
                Patreon sometimes needs a few minutes to verify everything. Please give it a few minutes (up to 1h).
              </li>
              <li>
                Check if you have linked your Discord account to your Patreon account (on the Patreon webpage!).
                Otherwise the bot will not know which user/server should receive the benefits.
              </li>
              <li>
                Check if you are on our <a href="https://discord.com/invite/cVzMUKX" target="_blank">Discord server</a>
                and have the Patreon role there.
              </li>
            </ul>
            <p>
              If you are still having problems, please contact us on Discord.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="p-2 p-md-5 guild-bg-dark">
  <div class="row justify-content-center">
    <div class="col-xl-8 col-lg-12">
      <table class="table table-striped table-dark" style="text-align:center" id="feature-table">
        <thead class="thead-dark">
          <tr>
            <th>Feature</th>
            <th>Free</th>
            <th class="d-none d-md-table-cell">
              Premium Via <br>
              <font style="color: #7289DA;"> ⛭ Gears </font> /
              <font style="color: #0070ba;"><fa-icon [icon]="faIcons.paypal"></fa-icon> PayPal </font> /
              <font style="color: #f96854;"><fa-icon [icon]="faIcons.patreon"></fa-icon> Patreon</font>
            </th>
            <th class="d-table-cell d-md-none">
              Premium
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Max Event Channels <a target="_blank" href="https://docs.raidorganizer.org/getting-started/setup-channel"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>2*</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Max Custom Signup Templates <a target="_blank" href="https://docs.raidorganizer.org/signup-templates"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>1*</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Max Repeating Events <a target="_blank" href="https://docs.raidorganizer.org/repeating-events"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>3*</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Max Event Templates <a target="_blank" href="https://docs.raidorganizer.org/event-templates"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>3*</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Max Event Start Notifications <a target="_blank" href="https://docs.raidorganizer.org/notification"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>1</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Max Event Deletion Time</td>
            <td>14</td>
            <td>60</td>
          </tr>
          <tr>
            <td>Repeating Event Creation In Advance</td>
            <td>7 Days</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Repeating Event Interval <a target="_blank" href="https://docs.raidorganizer.org/repeating-events/create-repeating-events#custom-interval"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>7 Days</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>Command <code>notify</code> User Limit <a target="_blank" href="https://docs.raidorganizer.org/notification/notify/"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td>25 Users</td>
            <td>unlimited</td>
          </tr>
          <tr>
            <td>Custom <code>notification</code> Messages <a target="_blank" href="https://docs.raidorganizer.org/notification/"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Automatic Event Cancellation <a target="_blank" href="https://docs.raidorganizer.org/notification/event-cancel-notification"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Automatic Voice Channel Creation <a target="_blank" href="https://docs.raidorganizer.org/voice-channel/"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Log Channels <a target="_blank" href="https://docs.raidorganizer.org/voice-channel/"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Event Images</td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Event Thumbnail</td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Event Footer Text&Icon</td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Event Overlap Notification</td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
          <tr>
            <td>Remove Ads On Guild View <a target="_blank" href="https://docs.raidorganizer.org/misc/ads/"><fa-icon class="premium_info" style="color: #3fb2cd;" [icon]="faIcons.info"></fa-icon></a></td>
            <td><fa-icon [icon]="faIcons.cross"></fa-icon></td>
            <td><fa-icon [icon]="faIcons.check"></fa-icon></td>
          </tr>
        </tbody>
      </table>
      <p>* Those are default numbers. You can increase them permanently by using <i>gears</i> or <i>PayPal</i>!</p>
    </div>
  </div>
</section>
