<div class="pt-3 ml-3 text-center">
  <h1>Gear Exchange</h1>
</div>

<div class="row">
  <div class="col">
    <mat-card class="overview-card">
      <mat-card-header>
        <mat-card-title class="text-center">
          <fa-icon [icon]="faIcons.gear" class="m-auto"></fa-icon>
          My Gears
        </mat-card-title>
        <mat-card-subtitle *ngIf="gearInfo$ | async as gearInfo else defaultCount">
          You currently have {{gearInfo.user.curr_gears}} gears</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="guild && gearInfo$ | async as gearInfo else defaultCount" class="pt-3">
        You can transfer your gears to <i>{{ guild.name }}</i>. <br />
        <form class="pt-2">
          <mat-form-field class="full-width-container" floatLabel="always">
            <mat-label for="userGearInput" >Transfer gears to {{ guild.name }}</mat-label>
            <input #gearAmount matInput type="number" class="form-control" id="userGearInput" placeholder="Gears"
              (input)="onGearUserInput($event.target)"
              min="0" [max]="+gearInfo.user.curr_gears" />
            <mat-hint align="end">Max: {{gearInfo.user.curr_gears}}</mat-hint>
          </mat-form-field>
          <button mat-button class="" mat-raised-button color="primary" (click)="contributeGears(+gearAmount.value); false" [disabled]="+gearAmount.value === 0 || +gearAmount.value > gearInfo.user.curr_gears">
            Contribute
          </button>
        </form>
        You can get gears by voting for <span class="text-info">Raid Organizer</span> on both <a href="https://top.gg/bot/658698521570181140" target="_blank">top.gg</a> and <a href="https://discordbotlist.com/bots/658698521570181140" target="_blank">discordbotlist.com</a>. <br />
        With gears, the organizers of <i>{{ guild.name }}</i> can increase limits or get <i>Premium</i> features.

      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row pt-5">
  <div class="col">
    <mat-card class="overview-card">
      <mat-card-header>
        <mat-card-title *ngIf="guild" class="text-center">
          <fa-icon [icon]="faIcons.gear" class="m-auto"></fa-icon>
          Guild Gears
        </mat-card-title>
        <mat-card-subtitle *ngIf="guild && gearInfo$ | async as gearInfo else defaultCount">
          <i>{{ guild.name }}</i> currently has {{gearInfo.guild.curr_gears}} gears</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="guild && gearInfo$ | async as gearInfo else defaultCount" class="pt-3">
        <div *ngIf="!guild.owner"class="alert alert-warning h5">
          &#x26A0; Only administrators are allowed to exchange gears.
        </div>
        With gears you can increase the limits of your guild permanently or activate <i>Premium</i> features. <br />
        Your current limits are:
        <ul>
          <li>Max. repeating events: <code>{{ gearInfo.limits.max_repeating_events }}</code></li>
          <li>Max. event channels: <code>{{ gearInfo.limits.max_event_channels }}</code></li>
          <li>Max. event templates: <code>{{ gearInfo.limits.max_event_templates }}</code></li>
          <li>Max. signup templates: <code>{{ gearInfo.limits.max_custom_templates }}</code></li>
        </ul>
        Premium status: <code>{{ guild.has_premium ? 'active' : 'inactive' }}</code>

        <div class="row pt-3">
          <div class="col-12">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label class="custom-label">Exchange Gears for goodies</mat-label>
              <mat-select [(ngModel)]="selectedFeature" name="feature" [disabled]="!guild.owner">
                <mat-option *ngFor="let feature of features" [value]="feature">
                  {{ feature.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="full-width-container" floatLabel="always">
              <mat-label class="custom-label">Set reminder <code>xy</code> days before premium expiration.</mat-label>
              <mat-select [(ngModel)]="premiumReminder" name="reminder" [disabled]="!isReminderEnabled()" placeholder="Do not remind me">
                <mat-option [value]="undefined">Do not remind me</mat-option>
                <mat-option *ngFor="let value of reminderOptions" [value]="value">{{ value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <button mat-button class="" mat-raised-button color="primary" (click)="consumeGears(selectedFeature); false" [disabled]="!selectedFeature">
              Consume
            </button>
          </div>
      </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #defaultCount>
  <div class="content_loader">
    <div class="content_loader_wrapper">
      <div class="content-loader-line-1"></div>
    </div>
  </div>
</ng-template>
