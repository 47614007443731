import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { faStickyNote, faLightbulb, faHandPointRight, faEdit, faBell } from '@fortawesome/free-regular-svg-icons';
import { faBullhorn, faCalendarAlt, faCalendarCheck, faChartLine, faDesktop, faInfinity, faServer, faShareAlt, faUserLock, faUsers } from '@fortawesome/free-solid-svg-icons';
import { BotResourceService } from '../../services/bot-resource.service';
import { Observable } from 'rxjs';
import { StatisticsData } from '../../models/bot/statistics';
import Parallax from 'parallax-js';
import AOS from 'aos';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./style.css', './home.component.css', ]
})
export class HomeComponent implements OnInit {

  faIcons = {
    monitor: faDesktop,
    stickyNote: faStickyNote,
    lightbulb: faLightbulb,
    chartline: faChartLine,
    handPointRight: faHandPointRight,
    server: faServer,
    users: faUsers,
    megaphone: faBullhorn,
    infinity: faInfinity,
    calendarDays: faCalendarAlt,
    edit: faEdit,
    calendarCheck: faCalendarCheck,
    shards: faShareAlt,
    userLock: faUserLock,
    reminder: faBell,
    custom: faEdit
  }

  statisticsData$: Observable<StatisticsData>;

  screenWidth: number;

  @ViewChild('customEmojiVid') customEmojiVid: ElementRef<HTMLVideoElement>;

  constructor(
    private oauthService: OAuthService,
    private botResourceService: BotResourceService,
  ) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.statisticsData$ = this.botResourceService.getStatisticsData();
    this.setupAOS(); // Call AOS setup function
  }

  ngAfterViewInit() {
    // Call Parallax setup function after the page is loaded
    this.setupParallax();
    this.ensureVideoPlays(this.customEmojiVid.nativeElement);
  }

  ensureVideoPlays(video: HTMLVideoElement): void {
    const keepPlaying = () => {
      if (video.paused) {
        video.muted = true;
        video.play();
      }
    };

    // Check if the video stops and restart it if necessary
    video.addEventListener('pause', keepPlaying);

    // Initial check to start the video if it is paused
    keepPlaying();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.screenWidth = event.target.innerWidth;
    this.setupParallax(); // Call the setupParallax function when window is resized
  }

  shouldShowParallaxEffect(): boolean {
    return this.screenWidth > 720;
  }

  login(): void {
    this.oauthService.initCodeFlow();
  }

  get isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private setupParallax(): void {
    if(this.shouldShowParallaxEffect()){
        var scene = document.getElementById('scene');
        if (scene)
          var parallaxInstance = new Parallax(scene);
    }
  }

  private setupAOS(): void {
    AOS.init({
      // once: true,
      duration: 1200,
    });
  }
}
