import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BotResourceService } from 'src/app/services/bot-resource.service';
import { BotGuild, ServerInfo } from 'src/app/models/bot/guild';
import { shareReplay, take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { GuildService } from 'src/app/services/guild.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-server-card',
  templateUrl: './server-card.component.html',
  styleUrls: ['./server-card.component.css'],
})
export class ServerCardComponent implements OnInit {
  faIcons = {
    trash: faTrashAlt,
  };

  guildId: string;
  guild: BotGuild;
  timezones: string[] = moment.tz.names();

  @Input() serverInfo$: Observable<ServerInfo>;

  constructor(
    private route: ActivatedRoute,
    private guildService: GuildService,
    private botService: BotResourceService,
    private toastr: ToastrService,

  ) { }

  async ngOnInit() {
    this.guildId = this.route.parent.snapshot.paramMap.get('id');

    this.guildService.getGuildData(this.guildId).subscribe(guild => {
      this.guild = guild;
    });

    this.serverInfo$ = this.botService.getGuildServer(this.guildId).pipe(
      shareReplay(1)
    );
  }

  private sendCommand(command: string, value: string, channelId?: string, actionCallback?: () => void): void {
    this.botService.postCommand(this.guildId, command, value, channelId).pipe(
      take(1),
    ).subscribe(
      () => {
        this.toastr.success('Saved successfully', command, { timeOut: 2000 });
        if (actionCallback) {
          actionCallback();
        }
      },
      (error) => {
        const errorMessage = error?.error?.error || 'Unknown error. Could not save.';
        this.toastr.error(errorMessage, command, { timeOut: 2000 });
      }
    );
  }

  setDateFormat(eventTarget: EventTarget): void {
    this.sendCommand('date_format set', (eventTarget as HTMLInputElement).value);
  }

  setTimeFormat(eventTarget: string): void {
    this.sendCommand('timeformat set', eventTarget);
  }

  guessTimezone(eventTarget: Event): string {
    eventTarget.stopPropagation();
    const timezone = moment.tz.guess();
    this.sendCommand('timezone set', timezone);
    this.serverInfo$.subscribe(serverInfo => {
      serverInfo.timezone = timezone;
    });
    return timezone;
  }

  setTimeZone(eventTarget: string): void {
    this.sendCommand('timezone set', eventTarget);
  }

  removeTemplate(templateId: string): void {
    this.sendCommand('signup_template remove', templateId, null, () => {
      this.serverInfo$.subscribe(guildInfo => {
        guildInfo.custom_templates = guildInfo.custom_templates.filter(entry => entry.identifier !== templateId);
      })
    });
  }

  setLanguage(eventTarget: string): void {
    this.sendCommand('language set', eventTarget);
  }

  setModeratorRole(eventTarget: EventTarget): void {
    // console.log((eventTarget as HTMLInputElement));
    this.botService.sendCommand(this.guildId, 'moderator_role set', (eventTarget as HTMLInputElement).value);
  }
}
