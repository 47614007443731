import { Component, Input, OnInit } from '@angular/core';
import { DiscordEmbed, DiscordEmoji } from '../embed-builder/embed.model';
import { EmbedService } from '../../services/embed.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-embed-preview',
  templateUrl: './embed-preview.component.html',
  styleUrls: ['./embed-preview.component.css']
})
export class EmbedPreviewComponent implements OnInit {
  @Input() embed: DiscordEmbed;
  @Input() emojis: DiscordEmoji[]

  constructor(
    private embedService: EmbedService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.embedService.currentEmbed.subscribe(embed => {
      this.embed = embed;
    });
    this.embedService.currentEmojis.subscribe(emojis => {
      this.emojis = emojis;
    });
  }

  onEmojiClick(emoji: DiscordEmoji) {
    // Increment count, send a reaction, etc.
  }

  isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
