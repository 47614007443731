import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { CustomEmoji } from 'src/app/models/bot/guild';

@Component({
  selector: 'app-emoji-picker-button',
  templateUrl: './emoji-picker-button.component.html',
  styleUrls: ['./emoji-picker-button.component.css']
})
export class EmojiPickerButtonComponent implements OnInit {

  @Input() customEmojis: CustomEmoji[] = []; // Custom emojis input
  @Input() defaultEmoji: string; // Default emojis input, if one is already selected
  @Input() resetSignal: boolean = false; // Reset signal input
  @Output() emojiSelected = new EventEmitter<string | CustomEmoji>(); // Event when an emoji is selected

  selectedEmoji: string | CustomEmoji = '❓'; // Default emoji
  backgroundColor = 'red'; // Default background color
  showEmojiPicker = false; // Toggle for showing the emoji picker


  ngOnInit(): void {
    this.initEmojiButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetSignal && changes.resetSignal.currentValue) {
      this.resetEmoji();
    } else {
      this.initEmojiButton();
    }
  }

  initEmojiButton() {
    if (this.defaultEmoji) {
      this.backgroundColor = 'green';
      // if `:` `<` and `>` is in the defaultEmoji, we have a custom emoji
      if (this.defaultEmoji.includes(':') && this.defaultEmoji.includes('<') && this.defaultEmoji.includes('>')) {
        // Loop over all custom emojis, check the id and check if the id is in the defaultEmoji string
        const customEmoji = this.customEmojis.find(emoji => this.defaultEmoji.includes(emoji.id));
        if (customEmoji) {
          this.selectedEmoji = `<img src="${customEmoji.imageUrl}" alt="${customEmoji.name}" class="custom-button-image">`
        } else {
          // Emoji does not belong to this server. We try best effort to display the emoji anyway.
          // Emoji comes in format of `<a:name:id>` if anmiated, else `<name:id>`
          // We need to get the id and append it to the url `https://cdn.discordapp.com/emojis/<id>`
          // We also need to check if the emoji is animated. If it is, we append `.gif` to the url, else `.png`
          const emojiParts = this.defaultEmoji.match(/<a?:(\w+):(\d+)>/);
          if (emojiParts && emojiParts.length === 3) {
            const isAnimated = this.defaultEmoji.startsWith('<a:');
            const emojiId = emojiParts[2];
            const imageUrl = `https://cdn.discordapp.com/emojis/${emojiId}${isAnimated ? '.gif' : '.png'}`;
            this.selectedEmoji = `<img src="${imageUrl}" alt="${emojiParts[1]}" class="custom-button-image">`;
            this.backgroundColor = 'orange';
          } else {
            // If we cannot parse the emoji format, fall back to the default emoji display
            this.selectedEmoji = this.defaultEmoji;
          }
        }
      } else {
        this.selectedEmoji = this.defaultEmoji
      }
    } else {
      this.selectedEmoji = '❓';
      this.backgroundColor = 'red';
    }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  onEmojiSelect(event: EmojiEvent) {
    if (event.emoji?.custom) {
      // For custom emojis, we might need to show an image
      this.selectedEmoji = `<img class="custom-button-image" src="${event.emoji.imageUrl}" alt="${event.emoji.shortNames[1]}">`;
    } else {
      // For standard emojis, just use the native Unicode character
      this.selectedEmoji = event.emoji.native;
    }
    this.backgroundColor = 'green'; // Change the background color to green on selection
    this.showEmojiPicker = false; // Close the emoji picker
    this.emojiSelected.emit(this.selectedEmoji); // Emit the selected emoji
  }

  resetEmoji() {
    this.selectedEmoji = '❓';
    this.backgroundColor = 'red';
  }
}
