import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
  production: true,
  baseUrlDiscord: 'https://discord.com/api',
  // baseUrlBot: 'http://78.47.203.135:5001',

  paypalClientId: 'Aa6LRx59ExuTX9kWp8WaFemGqqAjYmjYGxlYgtkfiajfrv1Rft-2vL2l6cLinajL6dRZp1V_MItxVrzn',
  paypalCurrency: 'EUR',
};

export const authConfig: AuthConfig = {
  issuer: 'https://discord.com/',
  redirectUri: 'https://raidorganizer.org/',
  clientId: '658698521570181140',
  dummyClientSecret: 'tiNaNLGLHICh8KTUuJV9qfcBJuD7qmLX',
  responseType: 'code',
  scope: 'identify connections guilds',

  oidc: false,
  loginUrl: 'https://discord.com/api/oauth2/authorize',
  tokenEndpoint: 'https://discord.com/api/oauth2/token',
  userinfoEndpoint: 'https://discordapp.com/api/users/@me',
  revocationEndpoint: 'https://discord.com/api/oauth2/token/revoke',
};
